import React from "react";
import RegisterForm from "../../../components/user/register-form/register-form";

export default class RegisterContainer extends React.Component {
    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
    }
    render() {
        return (
            <div>
                <div className="main-content">
                    <RegisterForm {...this.props} />
                </div>
            </div>
        )
    }
}
