
import { http } from "./http";
import { CREATE_CATEGORY } from "./constants";

export const createCategory = async (data) => {
    return http.post(CREATE_CATEGORY, data)
}

export const getCategoriesList = async (data) => {
    return http.get(CREATE_CATEGORY + '?' + data || '');
}

export const updateCategory = async (data) => {
    return http.put(CREATE_CATEGORY + '/' + data.id, data)
}

export const archiveCategory = async (data) => {
    return http.delete(CREATE_CATEGORY + '/' + data.id);
}
