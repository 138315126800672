import { CLIENT_UPDATED, CREATE_CLIENT_RES, NEW_CLIENT_SEARCH, SEARCH_CLIENT_RES, SEARCH_RESULT, CLIENT_CREATED, LIST_CLIENT_RES, CLIENT_NOTES, CLIENT_NOTE, TOTAL_CLIENTS } from '../helpers/constants';
import { createClient, getClient, searchClients, getClientList, updateClient, createClientNote, getClientNote } from '../api/client-api';
import { toast } from '../helpers/utility';
require('es6-promise').polyfill();

export function CreateClientAction(data) {
    return async function (dispatch) {
        try {
            const res = await createClient(data)
            if (!res.data.data.error) {
                dispatch({
                    type: CREATE_CLIENT_RES,
                    payload: res.data.data.client
                });
                dispatch({
                    type: CLIENT_CREATED,
                    payload: new Date().getTime()
                });
            }
            else {
                toast(res.data.data.message, 'error')
            }
        } catch (error) {
            toast(error.message, 'error')
        }

    };
}
export function CreateClientNotes(data) {
    return async function (dispatch) {
        const res = await createClientNote(data)
        if (!res.data.data.error) {
            dispatch({
                type: CLIENT_NOTE,
                payload: res.data.data.note
            });
        }
        else {
            toast(res.data.data.message, 'error')
        }
    };
}
export function GetClientNotes(data) {
    return async function (dispatch) {
        const res = await getClientNote(data)
        if (!res.data.data.error) {
            dispatch({
                type: CLIENT_NOTES,
                payload: res.data.data.notes
            });
        }
        else {
            toast(res.data.data.message, 'error')
        }
    };
}
export function SearchClientsAction(data, isUpdate, searchResult) {
    return async function (dispatch) {
        const res = await searchClients(data);
        dispatch({
            type: SEARCH_CLIENT_RES,
            payload: res.data.data.clients
        });
        dispatch({
            type: SEARCH_RESULT,
            payload: true
        });
        if (res.data.data.total >= 0) {
            dispatch({
                type: TOTAL_CLIENTS,
                payload: res.data.data.total
            });
        }
        if (!isUpdate) {
            dispatch({
                type: NEW_CLIENT_SEARCH,
                payload: new Date().getTime()
            });
        }
    };
}
export function updateClientAction(data) {
    return async function (dispatch) {
        const res = await updateClient(data)
        dispatch({
            type: CREATE_CLIENT_RES,
            payload: res.data.data.client
        });
        dispatch({
            type: CLIENT_UPDATED,
            payload: new Date().getTime()
        });
    };
}
export function getClientAction(id) {
    return async function (dispatch) {
        const res = await getClient(id)
        dispatch({
            type: CREATE_CLIENT_RES,
            payload: { ...res.data.data.client, date: new Date().getTime() }
        });
    };
}
export function getClientListAction(id) {
    return async function (dispatch) {
        const res = await getClientList()
        dispatch({
            type: LIST_CLIENT_RES,
            payload: res.data.data.clients
        });
    };
}



