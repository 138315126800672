import { MEDICAL_OBSERVATION, IS_MEDICAL_OBSERVATION } from "../helpers/constants";

export default function client(state = {
    isMedicalObservation: false,
    medicalObservations: []
}, action) {
    switch (action.type) {
        case MEDICAL_OBSERVATION:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, medicalObservations: action.payload }
                }
                return { ...state, medicalObservations: [...state.medicalObservations, action.payload] };
            }
        case IS_MEDICAL_OBSERVATION:
            {
                return { ...state, isMedicalObservation: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}