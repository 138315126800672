import React from 'react';
import Table from 'react-bootstrap/Table'
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getCellVisitAction } from '../../actions/cell-visit';
import { CELL_VISIT_TAB, RISK_FLAG_COLOR, DATE_FORMAT, TIME_FORMAT, TIME_ZONE, SEARCH_CLIENT_RES, TO_CELL_VISIT, DOMESTIC_FLAG_COLOR } from '../../helpers/constants';
import store from '../../store';
import { getUser } from '../../helpers/utility';
import FlagIcon from '@material-ui/icons/Flag';
import { BootstrapTooltip } from '../shared/hovertip/tooltip'

import "./dashboard.scss";

class CellVisitDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        store.dispatch(getCellVisitAction())
    }
    handleClick(patrol) {
        store.dispatch({ type: CELL_VISIT_TAB, payload: patrol, IS_CLICKED_CELL: true });
        // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [patrol] });
        setTimeout(() => {
            this.props.setKey('client')
        }, 500);
    }
    render() {

        return (
            <div className="active-user full-width">
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Location</th>
                            <th>Date</th>
                            <th>Time in</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.patrols.map((patrol, index) => (
                            <React.Fragment key={"CellVisitDashboard" + index}>
                                {(getUser().Role.admin || !patrol.isCheckout) &&

                                    <BootstrapTooltip title={patrol.created ? patrol.created.name : "Archived User"}>
                                        <tr style={{ cursor: "pointer" }} onClick={async () => { this.props.fromDashboard ? this.props.history.push(TO_CELL_VISIT + '/new/' + patrol.Client.id) : this.handleClick(patrol) }}>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {patrol.isViolence && <FlagIcon style={{ fill: DOMESTIC_FLAG_COLOR }} />}
                                                {patrol.Client.highRisk && <FlagIcon style={{ fill: RISK_FLAG_COLOR }} />}

                                                {/* {!patrol.isViolence && <img title='No "No contact" Domestic Violence'
                                                    src={require("../../assets/images/blue-flag.svg")}
                                                    alt="flag"
                                                />} */}
                                            </td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.firstName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.lastName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.phone || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.dob ? <Moment format={DATE_FORMAT}>{patrol.Client.dob}</Moment> : 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{this.props.locations?.find(elem => elem.id === patrol.locationId)?.name || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{patrol.timeIn}</Moment></td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{patrol.timeIn}</Moment></td>
                                            {/* <td className="bg-grey"></td> */}
                                            <td>
                                                <img
                                                    src={patrol.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                                    alt="logo"
                                                />
                                            </td>
                                        </tr>
                                    </BootstrapTooltip>
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        patrols: state.cellVisit.cellVisits,
        locations: state.location.locations
    };
};

export default connect(stateMap)(CellVisitDashboard);