import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import Search from "../../components/search/search";
import Select from 'react-dropdown-select';
import { uuid } from 'uuidv4';
import store from '../../store';
import { connect } from "react-redux";
import CellVisitDashboard from "../../components/dashboard/cell-visit-dashboad";
import TableCellVisit from "../../components/cell-visits/table-cell-visit";
import CellVisitDetailsForm from "./cell-visit-details-form";
import "./cell-visits.scss";
import { toast } from '../../helpers/utility';
import ConfirmCellVisitTable from "../confirm-cell-visit/confirm-cell-visit-table"
import { CreateCellVisitAction, updateCellVisitAction } from '../../actions/cell-visit';
import { CELL_VISIT_TAB, IS_CLICKED_CELL, SEARCH_CLIENT_RES, IS_MEDICAL_OBSERVATION, MEDICAL_OBSERVATION } from '../../helpers/constants';
import { SearchClientsAction } from '../../actions/client-action';

class CellVisitFacility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'Actives',
            isSaveButtons: true,
            selectedClientId: '',
            isMedicalCheck: false,
            clientToConfirm: [],
            clientForm: [],
            cellClickedData: {},
            concentClickedData: {
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
            },
            cell: {
                dropOff: '',
                timeIn: '',
                safePlaceDetails: '',
                violanceDetails: '',
                firstName: '',
                lastName: '',
                clientEmail: '',
                clientComments: '',
                actionTaken: '',
                inCharge: '',

                isViolence: false,
                isMedicalCondition: false,
                isServerRefused: false,
                isCheckout: false,
                isBelonging: false,
                isSafePlace: false,
                isReferral: false,
                isLeaveDiversion: 1,
                leaveDiversionComment1: "",
                leaveDiversionComment2: "",
                isArchived: false,
                clientId: 0,
                createdBy: 0,
                serverRefusedBy: 0,
                isAmbulance: false,
                isArrangements: 0,
                isAggression: false,
                isOnMedication: false,
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
                medicationName: '',
                medicationDate: '',
                medicationTime: '',
            }
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeConcent = this.onChangeConcent.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.tabChangeHandler = this.tabChangeHandler.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
    }

    onChange(e, loc, id) {
        let cellClickedData = this.state.cellClickedData
        if (loc === 'check') {
            cellClickedData[e.target.name] = eval(e.target.value)
            if (e.target.name == 'isMedicalCondition') {
                this.setState({ isMedicalCheck: eval(e.target.value) })
                store.dispatch({
                    type: IS_MEDICAL_OBSERVATION,
                    payload: eval(e.target.value)
                });
            }
        } else {
            cellClickedData[e.target.name] = e.target.value
        }
        this.setState({ cellClickedData })
    }
    onChangeConcent(e, loc, id) {
        let concentClickedData = this.state.concentClickedData
        concentClickedData[e.target.name] = eval(e.target.value)
        this.setState({ concentClickedData })
    }

    componentDidMount() {
        this.setState({ cellClickedData: this.state.cell });
        if (this.props.match.params.key) {
            this.tabChangeHandler(this.props.match.params.key);
        }
        if (this.props.match.params.id && this.props.searchClients.length > 0) {
            this.setState({ selectedClientId: this.props.match.params.id })
            setTimeout(() => {
                this.onRowSelect(false, this.props.match.params.id)
            }, 1000)
        } else {
            store.dispatch(SearchClientsAction(`from=cell&query=&page=1`))
        }
        if (localStorage.getItem('cellVisits')) {
            let cellVisits = JSON.parse(localStorage.getItem('cellVisits'))
            this.setState({ clientToConfirm: cellVisits })
        }
        this.setState({ key: 'Actives' })
    }
    onClose() {
        let data = this.state.cellClickedData
        data.isReferral = false
        this.setState({ cellClickedData: data })
    }
    onSave() {
        this.setState({ cellClickedData: { ...this.state.cellClickedData, ...this.state.concentClickedData } })
    }

    onRowSelect(e, id) {
        this.setState({ selectedClientId: id })
        let selectedClient = this.props.searchClients.filter(elem => elem.id === id)
        if (selectedClient.length > 0 && selectedClient[0].CellVisits.length > 0) {
            this.tabChangeHandler('client');
            this.setState({
                cellClickedData: {
                    ...selectedClient[0].CellVisits[0],
                    Client: {
                        id: selectedClient[0].id, name: selectedClient[0].name, firstName: selectedClient[0].firstName,
                        lastName: selectedClient[0].lastName, dob: selectedClient[0].dob, phone: selectedClient[0].phone
                    }
                }, isSaveButtons: false
            }, () => {
                store.dispatch({ type: CELL_VISIT_TAB, payload: { ...this.state.cellClickedData } })
                store.dispatch({ type: IS_CLICKED_CELL, payload: true });
            })
        } else {
            let cellClickedData = this.state.cell
            cellClickedData['clientId'] = id
            cellClickedData['timeIn'] = new Date()
            this.setState({ cellClickedData, isSaveButtons: true }, () => {
                store.dispatch({ type: IS_CLICKED_CELL, payload: true });
            })
        }
    }
    updateObjectInArray(array, action) {
        return array.map((item, index) => {
            if (index !== action.index) {
                return item
            }
            return {
                ...item,
                ...action.item
            }
        })
    }
    handleOnSubmit(e, flag) {
        e.preventDefault();
        let cellClickedData = this.state.cellClickedData
        if (flag === "save") {
            cellClickedData = { ...cellClickedData, tempId: uuid(),timeIn: new Date() }
        }

        if (this.props.isMedicalObservation) {
            cellClickedData['medicalObservation'] = this.props.medicalObservations
        } else {
            cellClickedData['medicalObservation'] = []
        }
        if (this.props.domesticViolance) {
            cellClickedData['domesticViolance'] = this.props.domesticViolance
        } else {
            cellClickedData['domesticViolance'] = []
        }
        if (!cellClickedData.isReferral) {
            cellClickedData['agreeFor'] = 0
            cellClickedData['agreeForDetail1'] = ''
            cellClickedData['agreeForDetail2'] = ''
            cellClickedData['agreeForDetail3'] = ''
            cellClickedData['agreeForService'] = 0
            cellClickedData['clientName'] = ''
            cellClickedData['thingsToConsider'] = false
            cellClickedData['thingsToConsider2'] = false
            cellClickedData['thingsToConsider3'] = false
        }
        if (cellClickedData.isSafePlace &&
            (!cellClickedData.safePlaceDetails || cellClickedData.safePlaceDetails == '')) {
            toast('Please provide SAFE PLACE contact person details', 'error')
            return false;
        } else {
            if (!cellClickedData.isSafePlace) {
                cellClickedData["safePlaceDetails"] = ""
                this.setState({ cellClickedData })
            }
        }
        if (cellClickedData.isServerRefused &&
            (cellClickedData.serverRefusedBy == 0)) {
            toast('Please provide Service Refused By', 'error')
            return false;
        } else {
            if (!cellClickedData.isServerRefused) {
                cellClickedData["serverRefusedBy"] = 0
                this.setState({ cellClickedData })
            }
        }
        if (!cellClickedData.clientEmail || cellClickedData.clientEmail === null) {
            toast('Please provide Client Email', 'error')
            return false;
        }
        if (!cellClickedData.firstName || cellClickedData.firstName === null) {
            toast('Please provide First Name', 'error')
            return false;
        }
        if (!cellClickedData.lastName || cellClickedData.lastName === null) {
            toast('Please provide Last Name', 'error')
            return false;
        }
        if (cellClickedData.isOnMedication &&
            (!cellClickedData.medicationName || cellClickedData.medicationName == '')) {
            toast('Please provide Medication name', 'error')
            return false;
        } else {
            if (!cellClickedData.isOnMedication) {
                cellClickedData["medicationName"] = ""
                this.setState({ cellClickedData })
            }
        }
        if (flag === 'checkOut') {
            if (cellClickedData.isLeaveDiversion === 1 &&
                (!cellClickedData.leaveDiversionComment1 || cellClickedData.leaveDiversionComment1 === '')) {
                toast('Please advise the location', 'error')
                return false;
            } else {
                if (cellClickedData.isLeaveDiversion === 4 || cellClickedData.isLeaveDiversion === 3 || cellClickedData.isLeaveDiversion === 2) {
                    cellClickedData["leaveDiversionComment1"] = ""
                    this.setState({ cellClickedData })
                }
            }
            if (cellClickedData.isLeaveDiversion === 4 &&
                (!cellClickedData.leaveDiversionComment2 || cellClickedData.leaveDiversionComment2 === '')) {
                toast('Please provide the details', 'error')
                return false;
            } else {
                if (cellClickedData.isLeaveDiversion === 1 || cellClickedData.isLeaveDiversion === 3 || cellClickedData.isLeaveDiversion === 2) {
                    cellClickedData["leaveDiversionComment2"] = ""
                    this.setState({ cellClickedData })
                }
            }
        }
        if (flag === 'checkOut' && !cellClickedData.isLeaveDiversion) {
            toast('Please provide client leave service', 'error')
            return false;
        }
        if (flag === 'update') {
            let cellVisits = JSON.parse(localStorage.getItem('cellVisits'))
            const index = cellVisits.findIndex(visit => cellClickedData.tempId === visit.tempId)
            let updatedCellvisits = []
            if (index > -1) {
                updatedCellvisits = this.updateObjectInArray(cellVisits, { index: index, item: cellClickedData })
            }
            this.setState({ clientToConfirm: updatedCellvisits })
            localStorage.setItem('cellVisits', JSON.stringify(updatedCellvisits))
        } else {
            if (localStorage.getItem('cellVisits')) {
                let cellVisits = JSON.parse(localStorage.getItem('cellVisits'))
                cellVisits = [...cellVisits, cellClickedData]
                this.setState({ clientToConfirm: cellVisits })
                localStorage.setItem('cellVisits', JSON.stringify(cellVisits))
            } else {
                let cellVisits = [cellClickedData]
                this.setState({ clientToConfirm: cellVisits })
                localStorage.setItem('cellVisits', JSON.stringify(cellVisits))
            }
        }
        this.setState({ key: 'Actives' })
        this.setState({
            cellClickedData: {
                dropOff: '',
                timeIn: '',
                safePlaceDetails: '',
                violanceDetails: '',
                clientEmail: '',
                firstName: '',
                lastName: '',
                clientComments: '',
                actionTaken: '',
                inCharge: '',
                isViolence: false,
                isMedicalCondition: false,
                isServerRefused: false,
                isCheckout: false,
                isBelonging: false,
                isSafePlace: false,
                isReferral: false,
                isLeaveDiversion: 1,
                leaveDiversionComment1: "",
                leaveDiversionComment2: "",
                isArchived: false,
                clientId: 0,
                createdBy: 0,
                serverRefusedBy: 0,
                isAmbulance: false,
                isArrangements: 0,
                isAggression: false,
                isOnMedication: false,
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
                medicationName: '',
                medicationDate: '',
                medicationTime: '',
            }
        })
        // localStorage.setItem('')
        store.dispatch({ type: MEDICAL_OBSERVATION, payload: [] });
        store.dispatch({ type: IS_MEDICAL_OBSERVATION, payload: false });
        store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        this.setState({ isSaveButtons: false })
    }

    componentDidUpdate(prevProp) {
        if (this.props.newSearch !== prevProp.newSearch) {
            this.setState({ selectedClientId: '' })
        }
        if (this.props.cellVisitTab && this.props.cellVisitTab !== prevProp.cellVisitTab) {
            this.setState({ cellClickedData: this.props.cellVisitTab })
        }
        if (JSON.stringify(prevProp.cellVisit) !== JSON.stringify(this.props.cellVisit)) {
            this.setState({ key: 'Actives' })
        }
    }
    handleDropDown(value, name) {
        let patrol = this.state.cellClickedData
        if (value[0]) {
            patrol[name] = value[0].value
            this.setState({ cellClickedData: patrol })
        }
    }
    componentWillUnmount() {

        store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
    }
    tabChangeHandler(key) {
        if (key === 'new') {
            this.setState({
                cellClickedData: {
                    dropOff: '',
                    timeIn: '',
                    safePlaceDetails: '',
                    violanceDetails: '',
                    clientEmail: '',
                    firstName: '',
                    lastName: '',
                    clientComments: '',
                    actionTaken: '',
                    inCharge: '',
                    isViolence: false,
                    isMedicalCondition: false,
                    isServerRefused: false,
                    isCheckout: false,
                    isBelonging: false,
                    isSafePlace: false,
                    isReferral: false,
                    isLeaveDiversion: 1,
                    leaveDiversionComment1: "",
                    leaveDiversionComment2: "",
                    isArchived: false,
                    clientId: 0,
                    createdBy: 0,
                    serverRefusedBy: 0,
                    isAmbulance: false,
                    isArrangements: 0,
                    isAggression: false,
                    isOnMedication: false,
                    thingsToConsider: false,
                    thingsToConsider2: false,
                    thingsToConsider3: false,
                    medicationName: '',
                    medicationDate: '',
                    medicationTime: '',
                }
            })
            store.dispatch({ type: SEARCH_CLIENT_RES, payload: [] })
            store.dispatch({ type: IS_CLICKED_CELL, payload: false });
            store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
        }
        // switch (key) {
        //     case 'actives':
        //         // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [] })
        //         // store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        //         // store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
        //         break;
        //     case 'new':
        //         if (!this.props.match.params.id && this.props.searchClients.length > 1) {
        //             // store.dispatch({ type: DIVERSION_TAB, payload: {} })
        //             store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        //         }
        //         // store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
        //         // store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
        //         break;
        //     case 'client':
        //         // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [] })
        //         // store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        //         // store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
        //         break;
        //     default:
        //         break;
        // }
        this.setState({ key })
    }

    render() {

        const options = [
            { label: "Brisbane", value: 1 },
            { label: "Bowman Johnson", value: 2 },
            { label: "Mackay", value: 3 },
            { label: "Townsville", value: 4 },
            { label: "Palm Island", value: 5 },
        ];
        return (
            <div className="cell-visits-section">
                <div className="tabs-section full-width mt-4">
                    {/* <Row>
                        <Col md={12}>
                         <CellVisitDetailsForm isMedicalCheck={this.state.isMedicalCheck} clientTab={true} concentClickedData={this.state.concentClickedData} handleDropDown={this.handleDropDown} changeState={this.onClose} onSave={this.onSave} isSaveButtons={false} patrol={this.props.cellVisitTab} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />
                        </Col>
                    </Row> */}
                    <Tabs defaultActiveKey="Actives" activeKey={this.state.key} onSelect={(key) => this.tabChangeHandler(key)} id="uncontrolled-tab-example">
                        {/* <Tab eventKey="actives" title={<span>Active </span>} className="tab-table"> */}
                        {/* <Row>
                        <Col md={12}>
                            <CellVisitDashboard setKey={(key) => this.setState({ key })} {...this.props} />
                        </Col>
                    </Row> */}
                        {/* </Tab> */}
                        <Tab eventKey="Actives" title={<span>Active</span>} className="tab-table comunity-user">
                            {/* <Search from={'cell'} {...this.props} /> */}
                            {/* <Row>
                        <Col md={12}>
                            <TableCellVisit handleDropDown={this.handleDropDown} isOnlyHead={this.props.isClickedCellClients} patrol={this.state.cellClickedData} clientId={this.state.selectedClientId} onChange={this.onChange} onRowSelect={this.onRowSelect} {...this.props} />
                        </Col>
                    </Row> */}
                            <div className="tab-table comunity-user">
                                <Row>
                                    <Col md={12}>
                                        <ConfirmCellVisitTable isActive={true} setKey={(key) => this.setState({ key })} clientToConfirm={this.state.clientToConfirm} parentRef={this} clientForm={this.state.clientForm} {...this.props} />
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="new" title={<span>Add New</span>} className="tab-table comunity-user">
                            <div className="tab-table comunity-user">
                                <Row>
                                    <Col md={12}>
                                        {this.state.key === 'new' && <CellVisitDetailsForm isMedicalCheck={this.state.isMedicalCheck} handleDropDown={this.handleDropDown} concentClickedData={this.state.concentClickedData} onSave={this.onSave} changeState={this.onClose} isSaveButtons={true} patrol={this.state.cellClickedData} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />}
                                    </Col>
                                </Row>
                            </div>
                        </Tab>
                        {this.state.key === 'cell-visit' && <Tab eventKey="cell-visit" title={<span>{this.state.cellClickedData.firstName + this.state.cellClickedData.lastName}</span>} className="tab-table comunity-user">
                            <div className="tab-table comunity-user">
                                <Row>
                                    <Col md={12}>
                                        {this.state.key === 'cell-visit' && <CellVisitDetailsForm isMedicalCheck={this.state.isMedicalCheck} handleDropDown={this.handleDropDown} concentClickedData={this.state.concentClickedData} onSave={this.onSave} changeState={this.onClose} isSaveButtons={false} patrol={this.state.cellClickedData} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />}
                                    </Col>
                                </Row>
                            </div>
                        </Tab>}
                        {/* this.props.cellVisitTab.id && <Tab eventKey="client" title={<span>{this.props.cellVisitTab.Client.name}</span>} className="tab-table comunity-user"> */}
                        {/* <Search {...this.props} /> */}
                        {/* <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' && <TableCellVisit isOnlyHead={this.props.isClickedCellClients} clientTab={true} handleDropDown={this.handleDropDown} onChange={this.onChange} patrol={this.props.cellVisitTab} onRowSelect={this.onRowSelect} clientId={this.props.cellVisitTab.Client.id} {...this.props} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' && <UserDetailCellVisit isMedicalCheck={this.state.isMedicalCheck} clientTab={true} concentClickedData={this.state.concentClickedData} handleDropDown={this.handleDropDown} changeState={this.onClose} onSave={this.onSave} isSaveButtons={false} patrol={this.props.cellVisitTab} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />}
                                </Col>
                            </Row> */}
                        {/* </Tab> */}
                    </Tabs>
                </div>
            </div>

        )
    }
}



const stateMap = (state) => {
    return {
        searchClients: state.client.searchClients,
        searchResult: state.client.searchResult,
        clickedCellClients: state.cellVisit.clickedCellClients,
        isClickedCellClients: state.cellVisit.isClickedCellClients,
        cellVisit: state.cellVisit.cellVisit,
        cellVisitTab: state.cellVisit.cellVisitTab,

        newSearch: state.client.newSearch,

        isMedicalObservation: state.medicalObservation.isMedicalObservation,
        medicalObservations: state.medicalObservation.medicalObservations,

        isDomesticViolance: state.domesticViolance.isDomesticViolance,
        domesticViolance: state.domesticViolance.domesticViolance,
        isOnline: state.global.isOnline
    };
};
export default connect(stateMap)(CellVisitFacility);