import { PATROL_SEARCH_RESULT, PETROL_CLIENT, COMMUNITY_PATROL_TAB, COMMUNITY_PATROLS, COMMUNITY_PATROL_SAVE } from "../helpers/constants";

export default function client(state = {
    clickedPatrolClients: {},
    isClickedPatrolClients: false,
    communityPatrol: {},
    searchPatrolClients: [],
    communityPatrols: [],
    communityPatrolTab: {}
}, action) {
    switch (action.type) {
        case PETROL_CLIENT:
            {
                return { ...state, clickedPatrolClients: action.payload };
            }
        case PATROL_SEARCH_RESULT:
            {
                return { ...state, searchPatrolClients: action.payload };
            }
        case COMMUNITY_PATROL_TAB:
            {
                return { ...state, communityPatrolTab: action.payload };
            }
        case COMMUNITY_PATROL_SAVE:
            {
                return { ...state, communityPatrol: action.payload };
            }
        case COMMUNITY_PATROLS:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, communityPatrols: action.payload }
                }
                return { ...state, communityPatrols: [...state.communityPatrols, action.payload] };
            }
        case 'IS_CLICKED_PATROL':
            {
                return { ...state, isClickedPatrolClients: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}