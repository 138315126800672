import { CREATE_CLIENT_RES, SEARCH_CLIENT_RES, LIST_CLIENT_RES, SEARCH_RESULT, NEW_CLIENT_SEARCH, CLIENT_UPDATED, CLIENT_CREATED, CLICKED_INDEX, TOTAL_CLIENTS } from "../helpers/constants";

export default function client(state = {
    client: {},
    clients: [],
    clientList: [],
    searchClients: [],
    searchResult: false,
    newSearch: new Date().getTime(),
    updated: new Date().getTime(),
    created: new Date().getTime(),
    clickedIndex: 0,
    totalClients: 0,
}, action) {
    switch (action.type) {
        case CREATE_CLIENT_RES: //on load api call response will store here
            {
                return { ...state, client: action.payload };
            }
        case TOTAL_CLIENTS: //on load api call response will store here
            {
                return { ...state, totalClients: action.payload };
            }
        case SEARCH_RESULT: //on load api call response will store here
            {
                return { ...state, searchResult: action.payload };
            }
        // case LIST_TOPIC_RES: //on load api call response will store here
        //     {
        //         if (action.payload.constructor.name === "Array") {
        //             return { ...state, clients: action.payload }
        //         }
        //         return { ...state, clients: [...state.clients, action.payload] };
        //     }
        case SEARCH_CLIENT_RES: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, searchClients: action.payload }
                }
                return { ...state, searchClients: [...state.searchClients, action.payload] };
            }
        case LIST_CLIENT_RES: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, clientList: action.payload }
                }
                return { ...state, clientList: [...state.clientList, action.payload] };
            }
        case NEW_CLIENT_SEARCH: //on load api call response will store here
            {
                
                return { ...state, newSearch: action.payload };
            }
        case CLIENT_UPDATED: //on load api call response will store here
            {
                return { ...state, updated: action.payload };
            }
        case CLIENT_CREATED: //on load api call response will store here
            {
                return { ...state, created: action.payload };
            }
        case CLICKED_INDEX: //on load api call response will store here
            {
                return { ...state, clickedIndex: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}