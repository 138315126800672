import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import CellVisitForm from "../../components/cell-visits/cell-visits";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import CellVisitFacility from "../../components/cell-visit-facility/cell-visit-facility";
import { connect } from "react-redux";

class MainCellVisitContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN
        }
    }
    componentDidUpdate(){
        
    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    {this.props.isOnline && <CellVisitForm {...this.props} />}
                    {!this.props.isOnline && <CellVisitFacility {...this.props} />}
                </Sidebar>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        isOnline: state.global.isOnline,
    };
};
export default connect(stateMap)(MainCellVisitContainer);