import { createCellVisit, getCellVisit, updateCellVisit, getCell } from '../api/cell-visit-api';
import { CELL_VISITS, CELL_VISIT_SAVE } from '../helpers/constants';
import { toast } from '../helpers/utility';
require('es6-promise').polyfill();

export function CreateCellVisitAction(data) {
    return async function (dispatch) {
        try {
            const res = await createCellVisit(data)
            dispatch({
                type: CELL_VISIT_SAVE,
                payload: res.data.data.cellVisit
            });
        } catch (error) {
            toast(error.message, 'error')
        }
    };
}

export function updateCellVisitAction(data) {
    return async function (dispatch) {
        const res = await updateCellVisit(data)
        dispatch({
            type: CELL_VISIT_SAVE,
            payload: res.data.data.cellVisit
        });
    };
}
export function getCellVisitAction(data) {
    return async function (dispatch) {
        const res = await getCellVisit(data)
        dispatch({
            type: CELL_VISITS,
            payload: res.data.data.cellVisits
        });
    };
}
export function getSingleCellVisit(id) {
    return async function (dispatch) {
        try {
            const res = await getCell(id)
            dispatch({
                type: CELL_VISIT_SAVE,
                payload: res.data.data.cellVisit[0]
            });
        } catch (error) {
            console.log('error here', error);
        }

    };
}
