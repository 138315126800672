import { ARCHIVE_CATEGORY_RES, CATEGORY_RES, CREATE_CATEGORY_RES } from "../helpers/constants";

export default function category(state = {
    category: {},
    categories: []
}, action) {
    switch (action.type) {
        case CREATE_CATEGORY_RES: //on load api call response will store here
            {
                return { ...state, category: action.payload };
            }
        case ARCHIVE_CATEGORY_RES:
            {
                return {
                    ...state, category: {
                        ...state.category,
                        deletedAt: new Date()
                    }
                };
            }
        case CATEGORY_RES: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, categories: action.payload }
                }
                return { ...state, categories: [...state.categories, action.payload] };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}