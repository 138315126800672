import { WELLBIENG_OBSERVATION, IS_WELLBIENG_OBSERVATION } from "../helpers/constants";

export default function client(state = {
    isWellbiengObservation: false,
    wellbiengObservations: []
}, action) {
    switch (action.type) {
        case WELLBIENG_OBSERVATION:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, wellbiengObservations: action.payload }
                }
                return { ...state, wellbiengObservations: [...state.wellbiengObservations, action.payload] };
            }
        case IS_WELLBIENG_OBSERVATION:
            {
                return { ...state, isWellbiengObservation: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}