import React from "react";
import { control, button } from 'react-validation';
import { Fragment } from 'react';
import ReactForm from 'react-bootstrap/Form';
import ReactButton from 'react-bootstrap/Button';
// import ReactSelect from 'react-select';
// import { Multiselect } from 'multiselect-react-dropdown';

// Define own Input component
const Select = ({ error, isChanged, isUsed, ...props }) => (
    <Fragment>
        {/* <Multiselect {...props} closeOnSelect={true} avoidHighlightFirstOption={true} />
        {isChanged && isUsed && error} */}
    </Fragment>
);
const Input = ({ error, isChanged, isUsed, ...props }) => {
    return (<Fragment>
        <ReactForm.Control {...props} />
        {props.value !== undefined && isChanged && isUsed && error}
    </Fragment>)

}

// Define own Button component
const Button = ({ hasErrors, ...props }) => {
    return (
        <ReactButton {...props} ></ReactButton>
    );
    // <ReactButton {...props} disabled={hasErrors}></ReactButton>
};

export default { ...ReactForm, Control: control(Input), CustomSelect: control(Select), CustomButton: button(Button) }
