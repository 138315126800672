import { REPORT_DATA, REPORT_DATA_CELL, REPORT_DATA_COMMUNITY, REPORT_DATA_DIVERSION, REPORT_DATA_CELL_DOWNLOAD, REPORT_DATA_COMMUNITY_DOWNLOAD, REPORT_DATA_DIVERSION_DOWNLOAD } from "../helpers/constants";
import { uuid } from 'uuidv4';

export default function client(state = {
    reportData: '',
    reportDataId: uuid(),
    reportDataCell: '',
    reportDataCellDownload: '',
    reportDataCommunity: '',
    reportDataCommunityDownload: '',
    reportDataDiversion: '',
    reportDataDiversionDownload: '',
    start: new Date(),
    end: new Date()
}, action) {
    switch (action.type) {
        case REPORT_DATA: //on load api call response will store here
            return { ...state, reportData: action.payload.data, start: action.payload.start, end: action.payload.end, reportDataId: uuid() };
        case REPORT_DATA_CELL: //on load api call response will store here
            return { ...state, reportDataCell: action.payload.data, start: action.payload.start, end: action.payload.end };
        case REPORT_DATA_CELL_DOWNLOAD: //on load api call response will store here
            return { ...state, reportDataCellDownload: action.payload.data, start: action.payload.start, end: action.payload.end };
        case REPORT_DATA_COMMUNITY: //on load api call response will store here
            return { ...state, reportDataCommunity: action.payload.data, start: action.payload.start, end: action.payload.end };
        case REPORT_DATA_COMMUNITY_DOWNLOAD: //on load api call response will store here
            return { ...state, reportDataCommunityDownload: action.payload.data, start: action.payload.start, end: action.payload.end };
        case REPORT_DATA_DIVERSION: //on load api call response will store here
            return { ...state, reportDataDiversion: action.payload.data, start: action.payload.start, end: action.payload.end };
        case REPORT_DATA_DIVERSION_DOWNLOAD: //on load api call response will store here
            return { ...state, reportDataDiversionDownload: action.payload.data, start: action.payload.start, end: action.payload.end };

        default:
            if (action.type.indexOf('@@') === -1) {
            }
    }
    return state;
}