import React from "react";
import { Link } from "react-router-dom/";
import "./404.scss";
import { TO_HOME } from "../../helpers/constants";



export default class Container404 extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="h-90vh flex-column d-flex align-items-center justify-content-center">
                    <Link className="animated-logo" to={"#"}>
                        <img className="mb-4" src={require("../../assets/images/logo.svg")} alt="logo" />
                    </Link>
                    <h1 className="mb-4">404</h1>
                    <h4>Take me back to <Link to={TO_HOME}>Home</Link></h4>
                </div>
            </div>
        )
    }
}