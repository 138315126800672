
import { http } from "../http";
import { LOGIN_API, GET_USER_BY_MAIL_API, PASSWORD_TOKEN_VERIFY_API,USER_INVITE_API ,PASSWORD_RESET_API, RESET_PASSWORD_REQUEST_API, REGISTER_API, PROFILE_API, USER_API, VERIFY_API, RESEND_API, SEARCH_USER_API, PREP_SSO_API } from "../constants";

export const login = async (data) => {
    return http.post(LOGIN_API, data)
}
export const verify = async (data) => {
    return http.post(VERIFY_API, data)
}
export const resend = async (data) => {
    return http.post(RESEND_API, data)
}
export const profile = async () => {
    return http.get(PROFILE_API)
}
export const PrepareSSO = async () => {
    return http.get(PREP_SSO_API);
}
export const getUser = async (id) => {
    return http.get(PROFILE_API + '/' + id)
}
export const profileUpdate = async (data) => {
    return http.put(PROFILE_API, data)
}
export const userUpdate = async (data) => {
    return http.put(PROFILE_API + '/' + data.id, data)
}
export const userArchive = async (id) => {
	return http.delete(USER_API + '/' + id)
}
export const userRestore = async (id) => {
	return http.put(USER_API + '/' + id, {
        deletedAt: null
    });
}
export const getProfileByEmail = async (data) => {
    return http.post(GET_USER_BY_MAIL_API, data)
}
export const register = async (data) => {
    return http.post(REGISTER_API, data)
}
export const createUser = async (data) => {
    return http.post(USER_API, data)
}
export const inviteAgain = async (data) => {
    return http.post(USER_INVITE_API, data)
}
export const listUsers = async (data) => {
    return http.get(USER_API + '?' + data)
}
export const listSearchedUsers = async (key,page) => {
    return http.get(SEARCH_USER_API + '/' + key + '/' + page)
}
export const confirmSignUp = async (token) => {
    return http.get(REGISTER_API + '/' + token)
}
export const resetPasswordRequest = async (data) => {
    return http.post(RESET_PASSWORD_REQUEST_API, data)
}
export const verifyPasswordToken = async (data) => {
    return http.post(PASSWORD_TOKEN_VERIFY_API, data)
}
export const resetPassword = async (data) => {
    return http.post(PASSWORD_RESET_API, data)
}