import { DOMESTIC_VIOLANCE, IS_DOMESTIC_VIOLANCE } from "../helpers/constants";

export default function client(state = {
    isDomesticViolance: false,
    domesticViolance: []
}, action) {
    switch (action.type) {
        case DOMESTIC_VIOLANCE:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, domesticViolance: action.payload }
                }
                return { ...state, domesticViolance: [...state.domesticViolance, action.payload] };
            }
        case IS_DOMESTIC_VIOLANCE:
            {
                return { ...state, isDomesticViolance: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}