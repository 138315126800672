import React from 'react';
import Select from 'react-dropdown-select';
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import moment from "moment"
import "../../components/community-patrol/community-patrol.scss";
import { DATE_FORMAT } from '../../helpers/constants';

class ClientViewTable extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="table-head-community full-width">
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            {this.props.location &&
                                <th>Location</th>
                            }
                            <th>DOB</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.client &&
                            <tr>
                                <td className={"bg-grey"}>


                                </td>
                                <td className={"bg-grey"}>{this.props.client.firstName}</td>
                                <td className={"bg-grey"}>{this.props.client.lastName}</td>
                                <td className={"bg-grey"}>{this.props.client.phone}</td>
                                {this.props.location &&
                                    <th className={"bg-grey"}>{this.props.location.name}</th>
                                }
                                <td className={"bg-grey"}>
                                    {this.props.client.dob ? <Moment format={DATE_FORMAT}>{moment(this.props.client.dob)}</Moment> : 'N/A'}
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default ClientViewTable;