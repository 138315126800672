import React from "react";
import UpdatePasswordForm from "../../../components/user/password-reset/update-password";

export default class UpdatePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="main-content">
          <UpdatePasswordForm {...this.props} />
        </div>
      </div>
    );
  }
}
