import React from 'react';
import Table from 'react-bootstrap/Table'
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getCommunityPatrolsAction } from '../../actions/community-patrol';
import { getAge, getUser } from '../../helpers/utility';
import { COMMUNITY_PATROL_TAB, RISK_FLAG_COLOR, FOOT_FLAG_COLOR, DOMESTIC_FLAG_COLOR, DATE_FORMAT, TIME_FORMAT, TIME_ZONE, SEARCH_CLIENT_RES, TO_COMMUNITY_PATROL } from '../../helpers/constants';
import store from '../../store';
import FlagIcon from '@material-ui/icons/Flag';
import "./community-patrol.scss";
import { BootstrapTooltip } from '../shared/hovertip/tooltip'
class ActiveUserCommunity extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        store.dispatch(getCommunityPatrolsAction())
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.patrols) !== JSON.stringify(this.props.patrols)) {

        }
    }

    handleClick(patrol) {
        store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: patrol, IS_CLICKED_PATROL: true });
        store.dispatch({ type: SEARCH_CLIENT_RES, payload: [patrol] });
        setTimeout(() => {
            this.props.setKey('client')
        }, 500);
    }
    render() {
        const options = [
            { label: "By MurriWatch", value: 1 },
            { label: "By Client", value: 2 },
        ];
        return (
            <div className="active-user full-width">
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Date</th>
                            <th>Time in</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.patrols.map((patrol, index) => (
                            <React.Fragment key={"ActiveCommunity" + index}>
                                {(getUser().Role.admin || !patrol.isCheckout) &&
                                    <BootstrapTooltip title={patrol.createdCommunity.name}>
                                        <tr style={{ cursor: "pointer" }} onClick={async () => { this.props.fromDashboard ? this.props.history.push(TO_COMMUNITY_PATROL + '/new/' + patrol.Client.id) : this.handleClick(patrol) }}>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {patrol.isViolence && <FlagIcon titleAccess={"Domestic Violance"} style={{ fill: DOMESTIC_FLAG_COLOR }} />}
                                                {patrol.footPrint && <FlagIcon titleAccess={"CP Foot Patrol"} style={{ fill: FOOT_FLAG_COLOR }} />}
                                                {patrol.Client.highRisk && <FlagIcon titleAccess={"High Risk Medical"} style={{ fill: RISK_FLAG_COLOR }} />}
                                            </td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.firstName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.lastName || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.phone || 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.dob ? <Moment format={DATE_FORMAT}>{patrol.Client.dob}</Moment> : 'N/A'}</td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{patrol.timeIn}</Moment></td>
                                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{patrol.timeIn}</Moment></td>
                                            <td>
                                                <img
                                                    src={patrol.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                                    alt="logo"
                                                />
                                            </td>
                                        </tr>
                                    </BootstrapTooltip>
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        patrols: state.communityPatrol.communityPatrols
    };
};

export default connect(stateMap)(ActiveUserCommunity);