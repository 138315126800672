import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TO_LOGIN } from "../../../helpers/constants";
import Form from 'react-validation/build/form';
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import "./password-reset.scss";
import store from "../../../store";
import { ResetPasswordAction, ResetPasswordRequest, VeirfyTokenAction } from "../../../actions/user/profile";
import { connect } from "react-redux";

const styles = theme => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: '#A91F2E',
            fontSize: '0.7365728900255755rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: '0.5728900255754475rem',
            [theme.breakpoints.down("767")]: {
                fontSize: "1.5rem"
            },
        }
    }
});

class UpdatePasswordForm extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.state = {
            user: {
                email: '',
                token: '',
                password: '',
                confirmPassword: '',
                phone: '',
            },
            show: false
        }
    }
    handleChange(e) {
        let user = this.state.user
        user[e.target.name] = e.target.value;
        this.setState({ user })
    }
    componentDidMount() {
        this.setState({ user: { token: this.props.match.params.token, email: '' } })
        store.dispatch(VeirfyTokenAction({ token: this.props.match.params.token }))
        // custom rule will have name 'isPasswordMatch'
        // ValidatorForm.addValidationRule('Password', (value) => {
        //     if (value.length < 8) {
        //         return false;
        //     }
        //     return true;
        // });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('Password');
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }
    // componentDidUpdate(prevProps) {
    //     // remove rule when it is not needed
    //     if (JSON.stringify(prevProps.resetPassword) !== JSON.stringify(this.props.resetPassword)) {
    //         setTimeout(() => {
    //             this.props.history.push(TO_LOGIN)
    //         }, 3000)
    //     }
    // }
    handleOnSubmit(e) {
        e.preventDefault();
        store.dispatch(ResetPasswordAction(this.state.user)) //set in session storage
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    render() {
        var alertClasses = classNames({
            'alert': true,
            'alert-danger': this.props.resetPassword.error ? this.props.resetPassword.error : '',
            'alert-success': !this.props.resetPassword.error 
        });
        const { classes } = this.props;
        return (
            <div className="reset-form">
                <div className="container">
                    <Row className="align-items-center content-row">
                        <Col md={6}>
                            <img
                                className="mb-4 logo-img"
                                src={require("../../../assets/images/logo.svg")}
                                alt="logo"
                            />
                        </Col>
                        <Col md={6}>
                            <div className="register-box">
                                <h3 className="text-center mb-3">Reset Password</h3>
                                {/* <p className="text-center">
                  Just fill in your email and we’ll send you a link to reset your password.
                          </p> */}
                                {Object.keys(this.props.resetPassword).length > 0 && <div className={alertClasses}> {this.props.resetPassword.message}</div>}
                                {/* <Form ref={c => { this.form = c }} onSubmit={this.handleOnSubmit}> */}
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleOnSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <TextValidator
                                                label="Password"
                                                className={classes.root}
                                                onChange={this.handleChange}
                                                id="outlined-adornment-password"
                                                variant="outlined"
                                                name="password"
                                                disabled={this.props.resetPassword.error}
                                                type="password"
                                                validators={['required', 'minStringLength:8']}
                                                errorMessages={['Password must include (minimum 8 characters)']}
                                                value={this.state.user.password}
                                                fullWidth
                                            />
                                            {/* <TextField type='password' className={classes.root} validations={[min8, password]} id="outlined-adornment-password" label="Password" variant="outlined" value={this.state.user.password} name="password" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-2">
                                            <TextValidator
                                                label="Re-Enter Password"
                                                onChange={this.handleChange}
                                                id="outlined-adornment-password"
                                                className={classes.root}
                                                name="confirmPassword"
                                                type="password"
                                                disabled={this.props.resetPassword.error}
                                                validators={['isPasswordMatch', 'required']}
                                                errorMessages={['password mismatch', 'this field is required']}
                                                value={this.state.user.confirmPassword}
                                                variant="outlined"
                                                fullWidth
                                            />
                                            {/* <TextField type='password' className={classes.root} validations={[required]} id="outlined-adornment-password" label="Re-Enter Password" variant="outlined" value={this.state.user.confirmPassword} name="confirmPassword" onChange={this.handleChange} fullWidth /> */}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={12} className="register-btn">
                                            <Button variant="primary" disabled={this.props.resetPassword.error} type="submit" size="lg" block>
                                                RESET PASSWORD
                                      </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center pt-4 color-alert">
                                            <Link to={TO_LOGIN}>
                                                LOGIN
                                      </Link>
                                        </Col>
                                    </Row>
                                </ValidatorForm>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}
const stateMap = (state) => {
    return {
        resetPassword: state.user.resetPassword
    };
};
export default compose(connect(stateMap), withStyles(styles),)(UpdatePasswordForm);