import React from 'react';
import Select from 'react-dropdown-select';
import Table from 'react-bootstrap/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Moment from 'react-moment';
import moment from "moment"
import store from '../../store';
import "./community-patrol.scss";
import { SearchClientsAction } from '../../actions/client-action';
import { DATE_FORMAT, RISK_FLAG_COLOR, FOOT_FLAG_COLOR, DOMESTIC_FLAG_COLOR, TIME_FORMAT, SEARCH_CLIENT_RES } from '../../helpers/constants';
import FlagIcon from '@material-ui/icons/Flag';

class TableHeadCommunity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: 1,
            page: 0,
            rowsPerPage: 20,
            lastSearch: '',
            clients: [],
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.sortBy = this.sortBy.bind(this);
        this.changeToggle = this.changeToggle.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeLastSearch = this.changeLastSearch.bind(this);
        this.changePerPage = this.changePerPage.bind(this);
    }
    changeToggle(v) {
        this.setState({ toggle: v })
    }
    changePage(v) {
        this.setState({ page: v })
    }
    changeLastSearch(v) {
        this.setState({ lastSearch: v })
    }
    changePerPage(v) {
        this.setState({ rowsPerPage: v })
    }
    componentDidMount() {
        this.setState({ clients: this.props.searchClients })

    }
    componentDidUpdate(prevProps) {
        if (prevProps.searchClients !== this.props.searchClients) {
            this.setState({ clients: this.props.searchClients })

        }
    }

    handleChangePage(event, newPage) {

        store.dispatch(SearchClientsAction(`from=community&query=${this.props.search}&page=${Number(newPage + 1)}`))
        this.setState({ page: newPage });
    }

    sortBy(order, by) {
        // 
        order = this.state.lastSearch != by ? true : order
        let clients = this.state.clients;
        clients.sort(function (a, b) {
            if (by == 'dropOff' || by == 'timeIn' || by == 'dob') {
                if (by == 'dropOff') {
                    var keyA = a.CommunityPatrols[0] ? a.CommunityPatrols[0][by] : 'zzzzzzzz', keyB = b.CommunityPatrols[0] ? b.CommunityPatrols[0][by] : 'zzzzzzzz';
                    if (order && a.CommunityPatrols[0]) {
                        return keyA.localeCompare(keyB)
                    } else if (!order && b.CommunityPatrols[0]) {
                        return keyB.localeCompare(keyA)
                    } else {
                        return 0
                    }
                }
                else {
                    if (by == 'timeIn')
                        var keyA = a.CommunityPatrols[0] ? new Date(a.CommunityPatrols[0][by]) : new Date(), keyB = b.CommunityPatrols[0] ? new Date(b.CommunityPatrols[0][by]) : new Date();
                    else
                        var keyA = a[by] ? new Date(a[by]) : new Date(), keyB = b[by] ? new Date(b[by]) : new Date();
                    if (order) {
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    } else {
                        if (keyB < keyA) return -1;
                        if (keyB > keyA) return 1;
                        return 0;
                    }
                }

            }
            else {
                var keyA = a[by], keyB = b[by];
                if (keyA && keyB) return order ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
            }
        })
        store.dispatch({
            type: SEARCH_CLIENT_RES,
            payload: clients
        });
        this.changeLastSearch(by)
        this.changeToggle(this.state.lastSearch != by ? order : !order)
    }
    render() {
        return (
            <div className="table-content mt-3 mb-3">
                <div className="client-data">
                    <TableContainer component={Paper}>
                        <Table className="text-center">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'firstName')
                                    }}>First Name</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'lastName')
                                    }}>Last Name</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'phone')
                                    }}>Mobile</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'dob')
                                    }}>DOB</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'locationId')
                                    }}>Pick Up</TableCell>
                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                                        this.sortBy(this.state.toggle, 'timeIn')
                                    }}>Time in</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!this.props.clientTab && !this.props.isOnlyHead &&
                                    (this.props.searchClients.map((client, index) =>
                                        <TableRow key={"searchClients-" + index} onClick={(e) => this.props.onRowSelect(e, client.id)}>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].isViolence && <FlagIcon titleAccess={"Domestic Violance"} style={{ fill: DOMESTIC_FLAG_COLOR }} />}
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].footPrint && <FlagIcon titleAccess={"CP Foot Patrol"} style={{ fill: FOOT_FLAG_COLOR }} />}
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].highRisk && <FlagIcon titleAccess={"High Risk Medical"} style={{ fill: RISK_FLAG_COLOR }} />}
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.firstName}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.lastName}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.phone}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {client.dob ? <Moment format={DATE_FORMAT}>{moment(client.dob)}</Moment> : 'N/A'}
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                LOCATION
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                <Moment format={TIME_FORMAT}>{client.CommunityPatrols && client.CommunityPatrols.length > 0 ? moment(client.CommunityPatrols[0].timeIn) : moment()}</Moment>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                {!this.props.clientTab && this.props.isOnlyHead &&
                                    (this.props.searchClients.filter(elem => elem.id === this.props.clientId).map((client, index) =>
                                        <TableRow>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].isViolence && <FlagIcon titleAccess={"Domestic Violance"} style={{ fill: DOMESTIC_FLAG_COLOR }} />}
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].footPrint && <FlagIcon titleAccess={"CP Foot Patrol"} style={{ fill: FOOT_FLAG_COLOR }} />}
                                                {client.CommunityPatrols && client.CommunityPatrols.length > 0 && client.CommunityPatrols[0].highRisk && <FlagIcon titleAccess={"High Risk Medical"} style={{ fill: RISK_FLAG_COLOR }} />}
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.firstName}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.lastName}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>{client.phone}</TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                {client.dob ? <Moment format={DATE_FORMAT}>{moment(client.dob)}</Moment> : 'N/A'}
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                <Select className="selection-select arrow-down custom-width no-border"
                                                    placeholder="Select Pick Up"
                                                    options={this.props.communityLocations}
                                                    values={[...this.props.communityLocations.filter(elem => elem.value === (client.CommunityPatrols && client.CommunityPatrols.length > 0 ? client.CommunityPatrols[0].locationId : ''))]}
                                                    name="locationId"
                                                    onChange={(e) => this.props.handleDropDown(e, 'locationId')}
                                                />
                                            </TableCell>
                                            <TableCell className={index % 2 === 0 ? "bg-grey" : ''}>
                                                <Moment format={TIME_FORMAT}>{client.CommunityPatrols && client.CommunityPatrols.length > 0 ? moment(client.CommunityPatrols[0].timeIn) : moment()}</Moment>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                {this.props.clientTab &&
                                    <TableRow>
                                        <TableCell className={"bg-grey"}>
                                            {this.props.CommunityPatrols && this.props.CommunityPatrols.length > 0 && this.props.CommunityPatrols[0].isViolence && <FlagIcon titleAccess={"Domestic Violance"} style={{ fill: DOMESTIC_FLAG_COLOR }} />}
                                            {this.props.CommunityPatrols && this.props.CommunityPatrols.length > 0 && this.props.CommunityPatrols[0].footPrint && <FlagIcon titleAccess={"CP Foot Patrol"} style={{ fill: FOOT_FLAG_COLOR }} />}
                                            {this.props.CommunityPatrols && this.props.CommunityPatrols.length > 0 && this.props.CommunityPatrols[0].highRisk && <FlagIcon titleAccess={"High Risk Medical"} style={{ fill: RISK_FLAG_COLOR }} />}
                                        </TableCell>
                                        <TableCell className={"bg-grey"}>{this.props.patrol.Client.firstName}</TableCell>
                                        <TableCell className={"bg-grey"}>{this.props.patrol.Client.lastName}</TableCell>
                                        <TableCell className={"bg-grey"}>{this.props.patrol.Client.phone}</TableCell>
                                        <TableCell className={"bg-grey"}>
                                            {this.props.patrol.Client.dob ? <Moment format={DATE_FORMAT}>{moment(this.props.patrol.Client.dob)}</Moment> : 'N/A'}
                                        </TableCell>
                                        <TableCell className={"bg-grey"}>

                                            <Select className="selection-select arrow-down custom-width no-border"
                                                placeholder="Select Pick Up"
                                                options={this.props.communityLocations}
                                                values={[...this.props.communityLocations.filter(elem => elem.value === (this.props.patrol.locationId ? this.props.patrol.locationId : ''))]}
                                                name="locationId"
                                                onChange={(e) => this.props.handleDropDown(e, 'locationId')}
                                            />
                                        </TableCell>
                                        <TableCell className={"bg-grey"}>
                                            <Moment format={TIME_FORMAT}>{this.props.patrol ? moment(this.props.patrol.timeIn) : moment()}</Moment>
                                        </TableCell>
                                    </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!this.props.clientTab && !this.props.isOnlyHead && <TablePagination
                        // rowsPerPageOptions={[5, 10, 20]}
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={this.props.totalClients}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                    />}
                </div>
            </div>
        )
    }
}

export default TableHeadCommunity;