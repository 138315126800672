import { CELL_SEARCH_RESULT, CELL_CLIENT, CELL_VISIT_TAB, CELL_VISITS, CELL_VISIT_SAVE, IS_CLICKED_CELL } from "../helpers/constants";

export default function client(state = {
    clickedCellClients: {},
    isClickedCellClients: false,
    cellVisit: {},
    searchCellClients: [],
    cellVisits: [],
    cellVisitTab: {}
}, action) {
    switch (action.type) {
        case CELL_CLIENT:
            {
                return { ...state, clickedCellClients: action.payload };
            }
        case CELL_SEARCH_RESULT:
            {
                return { ...state, searchCellClients: action.payload };
            }
        case CELL_VISIT_TAB:
            {
                return { ...state, cellVisitTab: action.payload };
            }
        case CELL_VISIT_SAVE:
            {
                return { ...state, cellVisit: action.payload };
            }
        case CELL_VISITS:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, cellVisits: action.payload }
                }
                return { ...state, cellVisits: [...state.cellVisits, action.payload] };
            }
        case IS_CLICKED_CELL:
            {
                return { ...state, isClickedCellClients: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}