import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  TO_LOGIN, TO_SIGNUP, TO_HOME, TO_DASHBOARD, TO_REGISTER, TO_RESET_PASS,
  TO_VERIFY, TO_CLIENTS, TO_ADMIN, TO_COMMUNITY_PATROL,
  TO_CELL_VISIT, TO_DIVERSION_CENTRE, TO_CREATE_CLIENTS,
  TO_ADMIN_LIST, TO_CONTACT, TO_PROFILE, TO_CONFIRM_CELLVISIT,
  TO_REPORT_FORM, TO_SERVICE_VIEW, TO_LOCATION, TO_CATEGORY,
  TO_REPORT_DIVERSION,
  TO_REPORT_CELLVISIT,
  TO_REPORT_COMMUNITYPETROL
} from "../helpers/constants"
import LoginContainer from "../containers/user/login/login";
import SignupContainer from "../containers/user/signup/signup";
import RegisterContainer from "../containers/user/register/register";
import verificationformContainer from "../containers/user/verify-form/verificationform";
import { getToken } from "../helpers/utility";
import UnauthenticatedRoute from "../helpers/UnauthenticatedRoute";
import AuthenticatedRoute from "../helpers/AuthenticatedRoute";
import Container404 from "../containers/404/404";
import PasswordResetContainer from "../containers/user/password-reset/password-reset";
import LandingPageContainer from "../containers/landing-page/landing-page";
import MainDashboardContainer from "../containers/dashboard/dashboard";
import MainAdminContainer from "../containers/admin/admin";
import ListAdminContainer from "../containers/admin/list";
import MainCommunityPatrolContainer from "../containers/community-patrol/community-patrol";
import MainClientsContainer from "../containers/client-search/clients";
import UpdatePasswordContainer from "../containers/user/update-password/update-password"
import ClientsContainer from "../containers/clients/clients";
import MainCellVisitContainer from "../containers/cell-visits/cell-visit";
import MainDiversionCentreContainer from "../containers/diversion-centre/diversion-centre";
import MainContactContainer from "../containers/contact/contact";
import MainProfileContainer from "../containers/profile/profile";
import MainConfirmCellVisitor from "../components/confirm-cell-visit/confirm-cell-visit";
import MainDiversionClientContainer from "../containers/clients-view/client-view-diversion";
import MainCommunityContainer from "../containers/clients-view/client-view-community";
import MainCellVisitClientContainer from "../containers/clients-view/client-view-cell-visit";
import MainReportFormContainer from "../containers/report-from/report-from";
import MainReportCellVisitContainer from "../containers/report-from/report-cellvisit";
import MainReportDiversionContainer from "../containers/report-from/report-diversion";
import MainReportCommunityContainer from "../containers/report-from/report-community";
import MainLocationContainer from "../containers/location/location";
import MainCategoryContainer from "../containers/category/category";

export default ({ socket }) => {
  let isAuthenticated = false
  try {
    const token = getToken();
    if (token)
      isAuthenticated = true
    else
      throw "User not authorized"
  } catch (e) {
    console.info(e)
  }

  return (

    <Switch>
      <Route exact path={TO_HOME} component={LandingPageContainer} />
      <UnauthenticatedRoute exact path={TO_LOGIN} component={LoginContainer} appProps={{ isAuthenticated }} />
      <UnauthenticatedRoute exact path={TO_SIGNUP} component={SignupContainer} appProps={{ isAuthenticated }} />
      <UnauthenticatedRoute exact path={TO_REGISTER + '/:token'} component={RegisterContainer} appProps={{ isAuthenticated }} />
      <UnauthenticatedRoute exact path={TO_RESET_PASS} component={PasswordResetContainer} appProps={{ isAuthenticated }} />
      <UnauthenticatedRoute exact path={TO_RESET_PASS + '/:token'} component={UpdatePasswordContainer} appProps={{ isAuthenticated }} />
      <UnauthenticatedRoute exact path={TO_VERIFY} component={verificationformContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CREATE_CLIENTS + '/:id?'} component={ClientsContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_DASHBOARD} component={MainDashboardContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CLIENTS} component={MainClientsContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_ADMIN + '/:id?'} component={MainAdminContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_ADMIN_LIST} component={ListAdminContainer} appProps={{ isAuthenticated }} />
      {/* <AuthenticatedRoute exact path={TO_COMMUNITY_PATROL} component={MainCommunityPatrolContainer} appProps={{ isAuthenticated }} /> */}
      <AuthenticatedRoute exact path={TO_COMMUNITY_PATROL + '/:key?/:id?'} component={MainCommunityPatrolContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CELL_VISIT + '/:key?/:id?'} component={MainCellVisitContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CONFIRM_CELLVISIT + '/:key?/:id?'} component={MainConfirmCellVisitor} appProps={{ isAuthenticated }} />
      {/* <AuthenticatedRoute exact path={TO_DIVERSION_CENTRE} component={MainDiversionCentreContainer} appProps={{ isAuthenticated }} /> */}
      <AuthenticatedRoute exact path={TO_DIVERSION_CENTRE + '/:key?/:id?'} component={MainDiversionCentreContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CONTACT} component={MainContactContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_PROFILE} component={MainProfileContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_SERVICE_VIEW + '/CommunityPatrols/:id?'} component={MainCommunityContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_SERVICE_VIEW + '/DiversionCenters/:id?'} component={MainDiversionClientContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_SERVICE_VIEW + '/CellVisits/:id?'} component={MainCellVisitClientContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_REPORT_FORM} component={MainReportFormContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_REPORT_DIVERSION} component={MainReportDiversionContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_REPORT_CELLVISIT} component={MainReportCellVisitContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_REPORT_COMMUNITYPETROL} component={MainReportCommunityContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_LOCATION} component={MainLocationContainer} appProps={{ isAuthenticated }} />
      <AuthenticatedRoute exact path={TO_CATEGORY} component={MainCategoryContainer} appProps={{ isAuthenticated }} />
      <Route path={'*'} component={Container404} appProps={{ isAuthenticated }} />
    </Switch>
  )
}
