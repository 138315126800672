import { IN_PROCESS, IS_ONLINE } from "../helpers/constants";

export default function event(state = {
    inProcess: false,
    isOnline: window.navigator.onLine,
}, action) {

    state = { ...state, lastAction: action.type };

    switch (action.type) {
        case IN_PROCESS: //on load api call response will store here
            {
                return { ...state, inProcess: action.payload };
            }
        case IS_ONLINE: //on load api call response will store here
            {
                return { ...state, isOnline: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}