import React from 'react';
import Table from 'react-bootstrap/Table'
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { getClientListAction, SearchClientsAction } from '../../actions/client-action';
import { getCommunityPatrolsAction } from '../../actions/community-patrol';
import { getAge, getUser, internetConnected } from '../../helpers/utility';
import { COMMUNITY_PATROL_TAB, DATE_FORMAT, TIME_FORMAT, TIME_ZONE, SEARCH_CLIENT_RES, TO_COMMUNITY_PATROL, CLICKED_INDEX, CELL_VISIT_TAB } from '../../helpers/constants';
import store from '../../store';
import ConfirmationModal from './confirmation-modal'

import "./community-patrol.scss";


class ConfirmCellVisitTable extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickActive = this.handleClickActive.bind(this);
        this.handleOnSaveCell = this.handleOnSaveCell.bind(this);
        this.state = {
            // isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            // clientToConfirm: [],
            // clientForm: []
            clickedData: {},
            show: false
        }
    }
    componentDidMount() {
        // store.dispatch(getCommunityPatrolsAction())
        
        if (internetConnected()) {
            store.dispatch(getClientListAction())
        }
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.clientToConfirm);
        if (JSON.stringify(prevProps.patrols) !== JSON.stringify(this.props.patrols)) {

        }
        if (JSON.stringify(prevProps.clients) !== JSON.stringify(this.props.clients)) {
            // this.setState({ key: 'actives' })
            // 
            // if (localStorage.getItem('cellVisits')) {
            //     let forms = JSON.parse(localStorage.getItem('cellVisits'))
            //     this.setState({ clientForm: forms })
            //     this.setState({ clientToConfirm: forms })
            // }
        }
    }

    handleClick(patrol, index) {
        // 
        // 
        // let data = this.props.clients.find(elem => elem.email === patrol.clientEmail)
        // let data = this.props.clientToConfirm.find(elem => elem.clientEmail === patrol.email)
        this.setState({ clickedData: { ...patrol } })
        this.setState({ show: true })
        
        store.dispatch({ type: CLICKED_INDEX, payload: index });
        // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [patrol] });
        // setTimeout(() => {
        //     this.props.setKey('client')
        // }, 500);
    }
    handleOnSaveCell(email) {
        let cellVisits = JSON.parse(localStorage.getItem('cellVisits'))
        let indexOfCellVisit = cellVisits.findIndex(elem => elem.clientEmail === email && !elem.synched)
        cellVisits[indexOfCellVisit].synched = true
        localStorage.setItem('cellVisits', JSON.stringify(cellVisits))
        let clientToConfirm = this.props.clientToConfirm
        clientToConfirm.splice(this.props.clickedIndex, 1)
        this.props.parentRef.setState({ clientToConfirm: clientToConfirm })
    }

    handleClickActive(patrol) {
        
        store.dispatch({ type: CELL_VISIT_TAB, payload: patrol, IS_CLICKED_CELL: true });
        // store.dispatch({ type: SEARCH_CLIENT_RES, payload: [patrol] });
        setTimeout(() => {
            this.props.setKey('cell-visit')
        }, 500);
    }
    render() {
        const options = [
            { label: "By MurriWatch", value: 1 },
            { label: "By Client", value: 2 },
        ];
        return (
            <div className="active-user full-width">
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Date</th>
                            <th>Time in</th>
                            {/* <th>Last Observ</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {this.props.patrols.map((patrol, index) => <tr style={{cursor: "pointer"}} onClick={async () => { store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: patrol, IS_CLICKED_PATROL: true }); await store.dispatch(SearchClientsAction(`from=community&query=&id=${patrol.Client.id}`)) ;this.props.setKey('client')   }}> */}
                        {this.props.clientToConfirm.map((patrol, index) =>
                        (((patrol.synched === undefined && !this.props.synched) || (patrol.synched && this.props.synched)) && <tr style={{ cursor: "pointer" }} onClick={() => !this.props.isActive ? this.handleClick(patrol, index) : this.handleClickActive(patrol, index)}>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}>
                                {/* {patrol.isViolence && <img title='"No contact" Domestic Violence Orders in place'
                                    src={require("../../assets/images/red-flag.svg")}
                                    alt="flag"
                                />}
                                {!patrol.isViolence && <img title='No "No contact" Domestic Violence'
                                    src={require("../../assets/images/blue-flag.svg")}
                                    alt="flag"
                                />} */}
                            </td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.firstName || 'N/A'}</td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.lastName || 'N/A'}</td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.phone || 'N/A'}</td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.dob ? <Moment format={DATE_FORMAT}>{patrol.dob}</Moment> : 'N/A'}</td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{patrol.timeIn}</Moment></td>
                            <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{patrol.timeIn}</Moment></td>
                            {/* <td className="bg-grey"></td> */}
                            {/* <td>
                                <img
                                    src={patrol.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td> */}
                        </tr>)
                        )}
                    </tbody>
                </Table>
                {this.state.show && <ConfirmationModal clickedData={this.state.clickedData} clients={this.props.clients} handleOnSaveCell={this.handleOnSaveCell} show={this.state.show} onClose={() => { this.setState({ show: false }) }} />}
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        // patrols: state.communityPatrol.communityPatrols,
        // searchResult: state.communityPatrol.activePatrolClients
        clients: state.client.clientList,
        clickedIndex: state.client.clickedIndex
    };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(ConfirmCellVisitTable);