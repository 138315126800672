//Base URL
export const BASE_URL = process.env.REACT_APP_API_SERVER;

//api URL
export const LOGIN_API = BASE_URL + '/login';
export const VERIFY_API = BASE_URL + '/verify';
export const RESEND_API = BASE_URL + '/resend';
export const PROFILE_API = BASE_URL + '/users/profile';
export const PREP_SSO_API = BASE_URL + '/users/prepareSSO'
export const GET_USER_BY_MAIL_API = BASE_URL + '/users-byMail';
export const REGISTER_API = BASE_URL + '/register';
export const USER_API = BASE_URL + '/users';
export const USER_INVITE_API = BASE_URL + '/users-invite';
export const SEARCH_USER_API = BASE_URL + '/search-users';
export const RESET_PASSWORD_REQUEST_API = BASE_URL + '/password/request';
export const PASSWORD_TOKEN_VERIFY_API = BASE_URL + '/password/verify';
export const PASSWORD_RESET_API = BASE_URL + '/password/reset';
export const CREATE_CLIENT = BASE_URL + '/clients';
export const CREATE_CLIENT_NOTE = BASE_URL + '/clients-notes';
export const CREATE_CONTACT = BASE_URL + '/contacts';
export const CREATE_LOCATION = BASE_URL + '/location';
export const CREATE_CATEGORY = BASE_URL + '/category';
export const CREATE_CLIENT_PATROL = BASE_URL + '/clients-patrol';
export const CREATE_CELL_VISIT = BASE_URL + '/clients-cell';
export const CREATE_DIVERSION_CENTER = BASE_URL + '/diversion-center'
export const CLIENT_SEARCH = BASE_URL + '/clients-search';
export const FETCH_REPORT = BASE_URL + '/fetch-report';
export const FETCH_REPORT_CELL = BASE_URL + '/fetch-report-cell';
export const DOWNLOAD_REPORT_CELL = BASE_URL + '/download-report-cell';
export const FETCH_REPORT_COMMUNITY = BASE_URL + '/fetch-report-community';
export const DOWNLOAD_REPORT_COMMUNITY = BASE_URL + '/download-report-community';
export const FETCH_REPORT_DIVERSION = BASE_URL + '/fetch-report-diversion';
export const DOWNLOAD_REPORT_DIVERSION = BASE_URL + '/download-report-diversion';

// NextView
export const NEXTVIEW_URL = process.env.REACT_APP_NEXTVIEW_URL;