
import {LOCATION_RES ,CREATE_LOCATION_RES} from '../helpers/constants';
import { getLocationsList,updateLocation,createLocation} from '../api/location-api';
import { toast } from '../helpers/utility';
require('es6-promise').polyfill();

export function CreateLocationAction(data) {
    return async function (dispatch) {
        const res = await createLocation(data)
        if(res && res.data.data.location){
            toast()
        }
        dispatch({
            type: CREATE_LOCATION_RES,
            payload: res.data.data.location
        });
    };
}
export function GetLocationAction(data) {
    return async function (dispatch) {
        const res = await getLocationsList(data)
        dispatch({
            type: LOCATION_RES,
            payload: res.data.data.locations
        });
       
    };
}

export function UpdateLocationAction(data) {
    console.log(data)
    return async function (dispatch) {
        const res = await updateLocation(data)
        dispatch({
            type: CREATE_LOCATION_RES,
            payload: res.data.data.location
        });
    };
}



