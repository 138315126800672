import React from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from 'react-dropdown-select';
import CustomForm from 'react-validation/build/form';
import ClientMedicalRiskForm from '../shared/client-medical-risk-form';
import ViolanceForm from '../shared/domestic-violance';
import EditIcon from '@material-ui/icons/Edit';
import CharacterCounter from '../../containers/character-counter/character-counter';

import "./cell-visits.scss";

import Modal from 'react-bootstrap/Modal'
import { toast } from '../../helpers/utility';
import { required } from '../../helpers/form-validation';

class CellVisitDetailsForm extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            show: false,
            isUpdate: false,
            petrol: {},
        };

        this.options6 = [
            { label: "Service 1", value: 1 },
            { label: "Service 2", value: 2 },
        ];
    }

    componentDidMount() {
        this.setState({ petrol: this.props.patrol })
    }
    handleSave() {
        if (this.props.concentClickedData.thingsToConsider || this.props.concentClickedData.thingsToConsider2 || this.props.concentClickedData.thingsToConsider3) {
            this.props.onSave();
            this.setState({ show: false })
        } else {
            toast("Please select any Consent box ", "error")
        }
    }

    handleClose() {
        if (JSON.stringify(this.state.petrol) !== JSON.stringify(this.props.patrol)) {
            if (this.state.petrol.thingsToConsider || this.state.petrol.thingsToConsider2 || this.state.petrol.thingsToConsider3) {
                this.setState({ show: false, isUpdate: false })
            } else {
                this.props.changeState();
                this.setState({ show: false, isUpdate: false })
            }
        } else if (!this.state.isUpdate) {
            this.props.changeState();
            this.setState({ show: false, isUpdate: false })
        } else {
            this.setState({ show: false, isUpdate: false })
        }
    }
    onChange(e) {
        this.props.onChange(e, 'loc', 'rby')
    }

    render() {

        var close = () => this.setState({ show: false });
        const options = [
            { label: "By MurriWatch", value: 1 },
            { label: "By Client", value: 2 },
        ];
        return (
            <div className="new-community update-community full-width">
                <CustomForm ref={c => { this.form = c }} onSubmit={(e) => this.props.onhandleOnSubmit(e)}>
                    <Row className="d-flex align-items-center p-2">
                        <Col md={3}>
                            <div className="form-consent-heading">
                                <Form.Label className="text-label">First Name:</Form.Label>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-consent-body">
                                <Form.Control type='text' value={this.props.patrol.firstName} name="firstName" onChange={(e) => this.props.onChange(e)} disabled={this.props.patrol.isCheckout} className="form-fields pt-0" placeholder="" />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-consent-heading">
                                <Form.Label className="text-label">Last Name:</Form.Label>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-consent-body">
                                <Form.Control type='text' value={this.props.patrol.lastName} name="lastName" onChange={(e) => this.props.onChange(e)} disabled={this.props.patrol.isCheckout} className="form-fields pt-0" placeholder="" />
                            </div>
                        </Col>
                    </Row>


                    <Row className="d-flex align-items-center p-2">
                        <Col md={6}>
                            <div className="form-consent-heading">
                                <Form.Label className="text-label">Client Email:</Form.Label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-consent-body">
                                <Form.Control type='email' value={this.props.patrol.clientEmail} name="clientEmail" onChange={(e) => this.props.onChange(e)} disabled={this.props.patrol.isCheckout} className="form-fields pt-0" placeholder="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center p-2">
                        <Col md={6}>
                            <div className="form-consent-heading">
                                <Form.Label className="text-label">Officer In Charge:</Form.Label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-consent-body">
                                <Form.Control value={this.props.patrol.inCharge} validation={[required]} name="inCharge" onChange={(e) => this.props.onChange(e)} disabled={this.props.patrol.isCheckout} className="form-fields pt-0" placeholder="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Does the client have any belongings on them (Including medication)? </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isBelonging} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox" name="isBelonging" className="custom-checkbox" />
                                    <label htmlFor="community-checkbox">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.isBelonging} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox1" name="isBelonging" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox1">Yes. If yes, please ensure all items are stored securely.</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row className="p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Is there a "safe place" contact person: </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isSafePlace} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox2" name="isSafePlace" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox2">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.isSafePlace} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox3" name="isSafePlace" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox3">Yes. If yes, provide details below.</label>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control disabled={(!this.props.patrol.isSafePlace || this.props.patrol.isCheckout)} value={this.props.patrol.safePlaceDetails} onChange={(e) => this.props.onChange(e)} as="textarea" rows={2} name="safePlaceDetails" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}

                    <Row className="d-flex align-items-center bg-grey p-2">
                        <ViolanceForm {...this.props}></ViolanceForm>
                    </Row>
                    <Row className="d-flex align-items-center p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Does the client have any known medical conditions?</Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isMedicalCondition} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox6" name="isMedicalCondition" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox6">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Control checked={this.props.patrol.isMedicalCondition} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox7" name="isMedicalCondition" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox7">Yes. If yes, you must complete the Client needs and risk identification form</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} style={(this.props.isMedicalCheck || this.props.patrol.isMedicalCondition) ? { border: '1px solid black', marginTop: '1rem' } : {}}>
                            {
                                this.props.patrol && (this.props.isMedicalCheck || this.props.patrol.isMedicalCondition) && <ClientMedicalRiskForm medObs={this.props.patrol} disabled={this.props.patrol.isCheckout} onFieldChange={this.props.onChange}></ClientMedicalRiskForm>
                            }
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row className="d-flex align-items-center h-100">
                                <Col md={8}>
                                    <Form.Label className="text-label">Was service refused? </Form.Label>
                                </Col>
                                <Col md={2}>
                                    <Form.Control checked={!this.props.patrol.isServerRefused} value={false} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox8" name="isServerRefused" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox8">No</label><br></br>
                                    <Form.Control checked={this.props.patrol.isServerRefused} value={true} onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox9" name="isServerRefused" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox9">Yes</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Select className="selection-select arrow-down custom-width"
                                        placeholder="By MurriWatch"
                                        options={options}
                                        values={[...options.filter(elem => elem.value === (eval(this.props.patrol.serverRefusedBy) ? eval(this.props.patrol.serverRefusedBy) : ''))]}
                                        name="serverRefusedBy"
                                        onChange={(e) => this.props.handleDropDown(e, 'serverRefusedBy')}
                                    />
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="client-record">
                                <h6>CLIENT RECORD</h6>
                                <p>
                                    <strong>Actions taken -</strong>
                                    Physical observations; observations of client wellbeing; who, what, when, outcome.
                                </p>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control value={this.props.patrol.actionTaken} onChange={(e) => this.props.onChange(e)} as="textarea" rows={1} name="actionTaken" />
                                </Form.Group>
                                <h6>Comments:</h6>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control value={this.props.patrol.clientComments} onChange={(e) => this.props.onChange(e)} disabled={this.props.patrol.isCheckout} as="textarea" rows={3} name="clientComments" />
                                </Form.Group>
                                {this.props.clientTab && <h6>CLIENT EXIT DETAILS</h6>}




                            </div>
                        </Col>
                    </Row>
                    {this.props.clientTab && <Row className="d-flex align-items-center bg-grey p-2">
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Label className="text-label">Has a referral been made on behalf of the client </Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Control checked={!this.props.patrol.isReferral} value={false} onChange={(e) => { this.props.onChange(e, 'check'); this.setState({ show: false }) }} type="radio" name="isReferral" type="radio" id="community-checkbox30" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox30">No</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={8}>
                                    <Form.Control checked={this.props.patrol.isReferral} value={true} onChange={(e) => { this.props.onChange(e, 'check'); this.setState({ show: true }) }} type="radio" name="isReferral" type="radio" id="community-checkbox31" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                    <label htmlFor="community-checkbox31">Yes. If yes, please ensure a Client referral and consent from is complete.</label>
                                </Col>
                                <Col md={4}>
                                    {this.props.patrol.isReferral && <Button type="button" size="sm" onClick={(e) => this.setState({ show: true, isUpdate: true })} variant="outline-danger" className="btn btn-sm mt-2"> <EditIcon></EditIcon> </Button>}

                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    <Row className="p-2">
                        {this.props.clientTab && <Col md={5}>
                            <Row>
                                <Col md={12}>
                                    <Form.Label className="text-label">How did the client leave the service?</Form.Label>
                                </Col>
                            </Row>
                        </Col>}
                        <Col md={7}>
                            <Row>
                                {this.props.clientTab && <>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 1} value={1} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox32" className="custom-checkbox" />
                                        <label htmlFor="community-checkbox32">Transported to a safe place (please advise the location)</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Control value={this.props.patrol.leaveDiversionComment1 ? this.props.patrol.leaveDiversionComment1 : ''} onChange={(e) => this.props.onChange(e)} name="leaveDiversionComment1" disabled={this.props.patrol.isCheckout} as="textarea" rows={2} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 2} value={2} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox33" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                        <label htmlFor="community-checkbox33">Transported via Ambulance</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 3} value={3} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox34" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                        <label htmlFor="community-checkbox34">Release from custody</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 5} value={5} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox34" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                        <label htmlFor="community-checkbox34">Picked up by QPS</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Control checked={this.props.patrol.isLeaveDiversion == 4} value={4} name="isLeaveDiversion" onChange={(e) => this.props.onChange(e, 'check')} type="radio" id="community-checkbox35" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                        <label htmlFor="community-checkbox35">Other (please provide details)</label>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group controlId="exampleForm.ControlTextarea2">
                                            <Form.Control value={this.props.patrol.leaveDiversionComment2 ? this.props.patrol.leaveDiversionComment2 : ''} onChange={(e) => this.props.onChange(e)} name="leaveDiversionComment2" disabled={this.props.patrol.isCheckout} as="textarea" rows={2} />
                                        </Form.Group>
                                    </Col>
                                </>}
                                {
                                    !this.props.patrol.isCheckout && (<Col md={12}>
                                        <div class="btn-sec full-width form-btn text-right">
                                            {
                                                this.props.isSaveButtons &&
                                                (<div class="btn-sec  form-btn">
                                                    <button type="submit" onClick={(e) => this.props.onhandleOnSubmit(e, 'save')} class="main-btn default-btn btn btn-primary">
                                                        Check In
                                                    </button>
                                                </div>)
                                            }
                                            {
                                                !this.props.isSaveButtons &&
                                                (<div class="btn-sec full-width form-btn text-right">
                                                    <button type="submit" onClick={(e) => this.props.onhandleOnSubmit(e, 'update')} class="main-btn default-btn btn btn-primary mr-2"> Save </button>
                                                    {/* <button type="button" onClick={(e) => this.props.onhandleOnSubmit(e, 'checkOut')} class="main-btn default-btn btn btn-primary"> Checkout  </button> */}
                                                </div>)
                                            }
                                            {/* <div class="btn-sec full-width form-btn text-right">
                                            <button type="submit" class="main-btn default-btn btn btn-primary">
                                                Checkout
                                            </button>
                                        </div> */}
                                        </div>
                                    </Col>)
                                }

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Modal
                                className="form-consent"
                                show={this.state.show}
                                onHide={close}
                                aria-labelledby="contained-modal-title"
                            >
                                <Modal.Body>
                                    <Container>
                                        <Row className="d-flex align-items-center">
                                            <Col md={4}>
                                                <div className="form-consent-heading">
                                                    <Form.Label className="text-label"><small>The Client Agrees That:</small></Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-consent-body">
                                                    <Form.Control checked={this.props.patrol.agreeFor == 1} value={1} onChange={(e) => this.props.onChange(e, 'check')} name="agreeFor" type="radio" id="community-checkbox69" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                                    <label for="community-checkbox69">A referral to a support service</label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <CharacterCounter>
                                                    <Select className="selection-select observation-input arrow-down custom-width"
                                                        placeholder="Service 1"
                                                        options={this.options6}
                                                        values={[...this.options6.filter(elem => (this.props.patrol.agreeFor == 1 && elem.value == (eval(this.props.patrol.agreeForService) ? eval(this.props.patrol.agreeForService) : '')))]}
                                                        onChange={(e) => this.props.handleDropDown(e, 'agreeForService')}
                                                        maxLength={255}
                                                    />
                                                </CharacterCounter>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={2}></Col>
                                            <Col md={10}>
                                                <div className="form-consent-body">
                                                    <Form.Control checked={this.props.patrol.agreeFor == 2} value={2} onChange={(e) => this.props.onChange(e, 'check')} name="agreeFor" type="radio" id="community-checkbox70" disabled={this.props.patrol.isCheckout} className="custom-checkbox" />
                                                    <label for="community-checkbox70">My personal details to be used so that the following additional supports can be provided to me:</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={2}></Col>
                                            <Col md={8}>
                                                <div className="form-consent-body form-textarea-padding">
                                                    <p className="form-para">family members contacted (provided details)</p>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control value={(this.props.patrol.agreeFor == 2 && this.props.patrol.agreeForDetail1) ? this.props.patrol.agreeForDetail1 : ''} onChange={(e) => this.props.onChange(e)} name="agreeForDetail1" disabled={this.props.patrol.isCheckout} as="textarea" rows={2} />
                                                    </Form.Group>
                                                    <p className="form-para">Personal belongings collected (provided details)</p>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control value={(this.props.patrol.agreeFor == 2 && this.props.patrol.agreeForDetail2) ? this.props.patrol.agreeForDetail2 : ''} onChange={(e) => this.props.onChange(e)} name="agreeForDetail2" disabled={this.props.patrol.isCheckout} as="textarea" rows={2} />
                                                    </Form.Group>
                                                    <p className="form-para">Others</p>
                                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control value={(this.props.patrol.agreeFor == 2 && this.props.patrol.agreeForDetail3) ? this.props.patrol.agreeForDetail3 : ''} onChange={(e) => this.props.onChange(e)} name="agreeForDetail3" disabled={this.props.patrol.isCheckout} as="textarea" rows={2} />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={12}>
                                                <div className="form-consent-heading">
                                                    <Form.Label className="text-label">Client Consent</Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-consent-body">
                                                    <p className="form-para">It has been explained to me that in order for a referral to be made to another service provider with additional support, I need to consent.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={2}>
                                                <div className="form-consent-heading">
                                                    <Form.Label className="text-label">Name of Client</Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="form-consent-body">
                                                    <Form.Control value={this.props.patrol.clientName} onChange={(e) => this.props.onChange(e)} name="clientName" disabled={this.props.patrol.isCheckout} className="form-fields text-center pt-0" placeholder="" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={12}>
                                                <div className="form-consent-heading pt-3">
                                                    <Form.Label className="text-label">Referral Form</Form.Label>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-consent-body">
                                                    <p className="form-para">Please make sure to consider that consent can only be obtained if <small>(All options must be ticked)</small>:</p>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.concentClickedData.thingsToConsider} value={!this.props.concentClickedData.thingsToConsider} onChange={(e) => this.props.onChangeConcent(e, 'check')} name="thingsToConsider" type="checkbox" id="community-checkbox71" className="custom-checkbox" />
                                                <label for="community-checkbox71">The Client is sober and not intoxicated</label>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.concentClickedData.thingsToConsider2} value={!this.props.concentClickedData.thingsToConsider2} onChange={(e) => this.props.onChangeConcent(e, 'check')} name="thingsToConsider2" type="checkbox" id="community-checkbox72" className="custom-checkbox" />
                                                <label for="community-checkbox72">The Client is sound mind and their decision-making is not impaired</label>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control checked={this.props.concentClickedData.thingsToConsider3} value={!this.props.concentClickedData.thingsToConsider3} onChange={(e) => this.props.onChangeConcent(e, 'check')} name="thingsToConsider3" type="checkbox" id="community-checkbox73" className="custom-checkbox" />
                                                <label for="community-checkbox73">The Client has provided verbal consent</label>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col md={6}>
                                                <Button onClick={this.handleClose} className="main-btn default-btn btn-right"> Close</Button>

                                                <Button onClick={this.handleSave} className="main-btn default-btn btn-right"> Save</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                            </Modal>
                        </Col>
                    </Row>

                </CustomForm>

            </div>
        )
    }
}

export default CellVisitDetailsForm;