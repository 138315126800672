import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table'
import Moment from 'react-moment';
import "./dashboard.scss";
import { connect } from 'react-redux';
import store from '../../store';
import { getCommunityPatrolsAction } from '../../actions/community-patrol';
import { DATE_FORMAT, TIME_FORMAT, RISK_FLAG_COLOR, FOOT_FLAG_COLOR, DOMESTIC_FLAG_COLOR } from '../../helpers/constants';
import FlagIcon from '@material-ui/icons/Flag';
class CommintyPortalDashboard extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        store.dispatch(getCommunityPatrolsAction())
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.patrols) !== JSON.stringify(this.props.patrols)) {
            

        }
    }

    render() {

        return (
            <div className="active-user full-width">
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>
                            <th>Drop Off</th>
                            <th>Time in</th>
                            {/* <th>Time Out</th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.patrols.map((patrol, index) =>
                            <tr>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}>

                                {patrol.isViolence && <FlagIcon titleAccess={"Domestic Violance"} style={{ fill: DOMESTIC_FLAG_COLOR }} />}}
                                {patrol.footPrint && <FlagIcon titleAccess={"CP Foot Patrol"} style={{ fill: FOOT_FLAG_COLOR }} />}
                                {patrol.highRisk && <FlagIcon titleAccess={"High Risk Medical"} style={{ fill: RISK_FLAG_COLOR }} />}

                                    {/* {patrol.isViolence && <img title='"No contact" Domestic Violence Orders in place'
                                        src={require("../../assets/images/red-flag.svg")}
                                        alt="flag"
                                    />}
                                    {!patrol.isViolence && <img title='No "No contact" Domestic Violence'
                                        src={require("../../assets/images/blue-flag.svg")}
                                        alt="flag"
                                    />} */}
                                </td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.firstName || 'N/A'}</td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.lastName || 'N/A'}</td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.Client.phone || 'N/A'}</td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={DATE_FORMAT}>{patrol.Client.dob}</Moment></td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}>{patrol.dropOff}</td>
                                <td className={index % 2 === 0 ? "bg-grey" : ''}><Moment format={TIME_FORMAT}>{patrol.timeIn}</Moment></td>
                                <td>
                                <img
                                    src={patrol.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td>
                            </tr>
                        )}

                        {/* <tr>
                            <td className="bg-grey">
                                <img
                                    src={require("../../assets/images/red-flag.svg")}
                                    alt="logo"
                                />
                            </td>
                            <td className="bg-grey">George</td>
                            <td className="bg-grey">Sawtell</td>
                            <td className="bg-grey">0411 111 111</td>
                            <td className="bg-grey">12/12/65</td>
                            <td className="bg-grey">TDC</td>
                            <td className="bg-grey">11:35AM</td>
                            <td className="bg-grey"></td>
                            <td>
                                <img
                                    src={require("../../assets/images/orange-checkbox.svg")}
                                    alt="logo"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <img
                                    src={require("../../assets/images/blue-flag.svg")}
                                    alt="logo"
                                />
                            </td>
                            <td>Kal Aaron</td>
                            <td>Smith</td>
                            <td>0411 111 111</td>
                            <td>1/11/82</td>
                            <td>TDC</td>
                            <td>11:45AM</td>
                            <td className="p-0">5:40PM</td>
                            <td>
                                <img
                                    src={require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bg-grey">
                                <img
                                    src={require("../../assets/images/red-flag.svg")}
                                    alt="logo"
                                />
                            </td>
                            <td className="bg-grey">George</td>
                            <td className="bg-grey">Sawtell</td>
                            <td className="bg-grey">0411 111 111</td>
                            <td className="bg-grey">12/12/65</td>
                            <td className="bg-grey">TDC</td>
                            <td className="bg-grey">11:35AM</td>
                            <td className="bg-grey">5:40PM</td>
                            <td>
                                <img
                                    src={require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={require("../../assets/images/blue-flag.svg")}
                                    alt="logo"
                                />
                            </td>
                            <td>Kal Aaron</td>
                            <td>Smith</td>
                            <td>0411 111 111</td>
                            <td>1/11/82</td>
                            <td>TDC</td>
                            <td>11:45AM</td>
                            <td className="p-0">5:40PM</td>
                            <td>
                                <img
                                    src={require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td>
                        </tr> */}
                    </tbody>
                </Table>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        patrols: state.communityPatrol.communityPatrols,
        // searchResult: state.communityPatrol.activePatrolClients
    };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(CommintyPortalDashboard);
