import { CONTACTS_RES, TOTAL_CONTACTS,CREATE_CLIENT_RES, CREATE_CONTACT_RES, SEARCH_CLIENT_RES, SEARCH_RESULT } from "../helpers/constants";

export default function contact(state = {
    contact: {},
    contacts: [],
    totalContacts: 0,
    searchResult: false
}, action) {
    switch (action.type) {
        case CREATE_CONTACT_RES: //on load api call response will store here
            {
                return { ...state, contact: action.payload };
            }
        case TOTAL_CONTACTS: //on load api call response will store here
            {
                return { ...state, totalContacts: action.payload };
            }
        // case SEARCH_RESULT: //on load api call response will store here
        //     {
        //         return { ...state, searchResult: action.payload };
        //     }
        // case LIST_TOPIC_RES: //on load api call response will store here
        //     {
        //         if (action.payload.constructor.name === "Array") {
        //             return { ...state, clients: action.payload }
        //         }
        //         return { ...state, clients: [...state.clients, action.payload] };
        //     }
        // case SEARCH_CLIENT_RES: //on load api call response will store here
        //     {
        //         if (action.payload.constructor.name === "Array") {
        //             return { ...state, searchClients: action.payload }
        //         }
        //         return { ...state, searchClients: [...state.searchClients, action.payload] };
        //     }
        case CONTACTS_RES: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, contacts: action.payload }
                }
                return { ...state, contacts: [...state.contacts, action.payload] };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}