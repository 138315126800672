import { combineReducers } from 'redux';
import user from "./user";
import client from "./client-reducer"
import contact from "./contact-reducer"
import communityPatrol from "./community-patrol-reducer"
import diversionCenter from "./diversion-center-reducer"
import medicalObservation from "./medical-observation-reducer"
import wellbiengObservation from "./wellbieng-observation-reducer"
import clientObservation from "./client-observation-reducer"
import domesticViolance from "./domestic-violance-reducer"
import clientBackground from "./client-background-reducer"
import report from "./report-reducer"
import location from "./location-reducer"
import category from "./category-reducer"
import notes from "./notes-reducer"
import global from "./global"
import cellVisit from "./cell-visit-reducer"
import { IS_LOGIN } from '../helpers/constants';
const appReducer = combineReducers({
    user,
    client,
    communityPatrol,
    diversionCenter,
    global,
    contact,
    wellbiengObservation,
    clientObservation,
    domesticViolance,
    cellVisit,
    medicalObservation,
    clientBackground,
    report,
    notes,
    location,
    category
})

const rootReducer = (state, action) => {
    if (action.type === IS_LOGIN) {
        if (!action.payload)
            state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;