import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import ClientViewCellVisit from "../../components/clients-view/client-view-cell-visit";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";

import { connect } from "react-redux";
import store from '../../store';
import { getSingleCellVisit } from "../../actions/cell-visit";
class MainCellVisitClientContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            singleCell: {}
        }
    }

    
    componentDidMount(){
        
        if (this.props.match.params.id) {
            store.dispatch(getSingleCellVisit(this.props.match.params.id) )           
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.cellVisit !== this.props.cellVisit){
            this.setState({singleCell: this.props.cellVisit})
            
                      
        }
    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    <ClientViewCellVisit cell={this.state.singleCell} {...this.props} />
                </Sidebar>
            </div>
        )
    }
}


const stateMap = (state) => {
    return {
        // cell: state.cell.diversion,
        cellVisit: state.cellVisit.cellVisit,
    };
};

export default connect(stateMap)(MainCellVisitClientContainer);