import { notify } from 'react-notify-toast';
import { TOAST_DURATION, TOKEN_COOKIE, PATH_COOKIE, USER_COOKIE, IS_LOGIN, COOKIE_EXPIRE, TO_LOGIN } from './constants';
import cookie from 'react-cookies'
import store from '../store';
import moment from "moment"
import { init } from '../api/http';
import pathToRegexp from 'path-to-regexp';

export const toast = (message = "Success", type = "success") => {
    let options = {}
    if (type === 'info') {
        type = 'custom'
        options = { background: '#006EB7', text: "#FFFFFF" };
    }
    notify.show(message, type, TOAST_DURATION, options)
}

export const setUser = (data) => {
    if (data !== undefined) {
        cookie.save(USER_COOKIE, data, { path: PATH_COOKIE, expires: moment().add(COOKIE_EXPIRE, 's')._d })
    }
}
export const getUser = (data) => {
    return cookie.load(USER_COOKIE)
}
export const setToken = (data) => {
    if (data !== undefined) {
        cookie.save(TOKEN_COOKIE, data, { path: PATH_COOKIE, expires: moment().add(COOKIE_EXPIRE, 's')._d })
        init()
    }
}
export const getAge = (client) => {
    if (client.dob !== null && client.dob !== '' && getUser()) {
        let age = moment().diff(moment(client.dob, 'YYYYMMDD'), 'years')
        if (age < 18 && !getUser().Role.admin) {

            return false
        } else {
            return true
        }
    } else {
        return true
    }
}

export const getToken = () => {
    return cookie.load(TOKEN_COOKIE)
}
export const internetConnected = () => {
    if (window.navigator.onLine) {
        return true
    } else {
        return false
    }
}
export const getTimeZone = () => {
    return /\((.*)\)/.exec(new Date().toString())[1].split(" ").map((n) => n[0]).join("");
}

export const logout = () => {
    cookie.remove(TOKEN_COOKIE, { path: PATH_COOKIE })
    cookie.remove(USER_COOKIE, { path: PATH_COOKIE })
    store.dispatch({ type: IS_LOGIN, payload: false })
    window.location.pathname = TO_LOGIN
    // init()
}

export const markActive = (route, history) => {
    const regexp = pathToRegexp(route + '/:id?');

    // 
    if (regexp.exec(history.location.pathname))
        return true
    else
        return false
}