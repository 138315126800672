import { CLIENT_BACKGROUND, IS_CLIENT_BACKGROUND } from "../helpers/constants";

export default function client(state = {
    isClientBackground: false,
    clientBackgrounds: []
}, action) {
    switch (action.type) {
        case CLIENT_BACKGROUND:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, clientBackgrounds: action.payload }
                }
                return { ...state, clientBackgrounds: [...state.clientBackgrounds, action.payload] };
            }
        case IS_CLIENT_BACKGROUND:
            {
                return { ...state, isClientBackground: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}