import { CLIENT_NOTES, CLIENT_NOTE } from "../helpers/constants";

export default function client(state = {
    clientNotes: [],
    clientNote: {},
}, action) {
    switch (action.type) {
        case CLIENT_NOTES: //on load api call response will store here
            {
                return { ...state, clientNotes: action.payload};
            }
        case CLIENT_NOTE: //on load api call response will store here
            {
                return { ...state, clientNote: action.payload};
            }

        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}