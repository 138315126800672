
import { CATEGORY_RES, CREATE_CATEGORY_RES, ARCHIVE_CATEGORY_RES } from '../helpers/constants';
import { getCategoriesList, updateCategory, createCategory, archiveCategory } from '../api/category-api';
import { toast } from '../helpers/utility';
require('es6-promise').polyfill();

export function CreateCategoryAction(data) {
    return async function (dispatch) {
        const res = await createCategory(data)
        if (res && res.data.data.category) {
            toast()
        }
        dispatch({
            type: CREATE_CATEGORY_RES,
            payload: res.data.data.category
        });
    };
}
export function GetCategoryAction(data) {
    return async function (dispatch) {
        const res = await getCategoriesList(data)
        dispatch({
            type: CATEGORY_RES,
            payload: res.data.data.categories
        });

    };
}

export function UpdateCategoryAction(data) {
    return async function (dispatch) {
        const res = await updateCategory(data)
        dispatch({
            type: CREATE_CATEGORY_RES,
            payload: res.data.data.category
        });
    };
}

export function ArchiveCategoryAction(data) {
    return async function (dispatch) {
        const res = await archiveCategory(data);
        toast();
        dispatch({
            type: ARCHIVE_CATEGORY_RES,
            payload: res.data.data.category
        });
    };
}




