
import { http } from "./http";
import { CREATE_CLIENT_PATROL } from "./constants";

export const createClientPatrol = async (data) => {
    return http.post(CREATE_CLIENT_PATROL, data)
}
export const getClientPatrol = async (data) => {
    return http.get(CREATE_CLIENT_PATROL, data)
}
export const getPatrol = async (id) => {
    return http.get(CREATE_CLIENT_PATROL + '/' + id)
}
export const updateClientPatrol = async (data) => {
    return http.put(CREATE_CLIENT_PATROL + '/' + data.id, data)
}

