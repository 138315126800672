
import { http } from "./http";
import { CREATE_CLIENT, CLIENT_SEARCH, CREATE_CLIENT_NOTE } from "./constants";

export const createClient = async (data) => {
    return http.post(CREATE_CLIENT, data)
}
export const createClientNote = async (data) => {
    return http.post(CREATE_CLIENT_NOTE, data)
}
export const getClient = async (data) => {
    return http.get(CREATE_CLIENT + '/' +data)
}
export const getClientNote = async (data) => {
    return http.get(CREATE_CLIENT_NOTE + '/' +data)
}
export const getClientList = async (data) => {
    
    return http.get(CREATE_CLIENT )
}
export const searchClients = async (data) => {
    
    return http.get(CLIENT_SEARCH + '?' + data)
}
export const updateClient = async (data) => {
    return http.put(CREATE_CLIENT + '/' + data.id, data)
}
