import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, ModalFooter, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom/";
import Table from 'react-bootstrap/Table'
import store from "../../store";
import { CreateCellVisitAction } from "../../actions/cell-visit";
import Select from 'react-select';
import { CreateClientAction } from "../../actions/client-action";
import { connect } from "react-redux";
import { CREATE_CLIENT_RES } from "../../helpers/constants";
// import "./confirmation.scss";

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);
        this.state = {
            // isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            // clientToConfirm: [],
            // clientForm: []
            clickedData: {},
            options: [],
            value: {},
            // show: false
        }
    }
    componentDidMount() {
        // store.dispatch(getCommunityPatrolsAction())
        // 
        //     // 
        let optionClient = this.props.clients.map(elem => {
            return {
                value: elem.email,
                label: elem.email
            }
        })
        this.setState({ options: optionClient })
        this.setState({ value: optionClient.find(elem => elem.value === this.props.clickedData.clientEmail) })
        //     setValue(optionClient.find(elem => elem.value === props.clickedData.clientEmail))
        //     

    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.patrols) !== JSON.stringify(this.props.patrols)) {

        }
        if (JSON.stringify(prevProps.clients) !== JSON.stringify(this.props.clients)) {
            let optionClient = this.props.clients.map(elem => {
                return {
                    value: elem.email,
                    label: elem.email
                }
            })
            this.setState({ options: optionClient })
            this.setState({ value: optionClient.find(elem => elem.value === this.props.clickedData.clientEmail) })
        }
        if (JSON.stringify(prevProps.client) !== JSON.stringify(this.props.client)) {
            store.dispatch(CreateCellVisitAction({
                actionTaken: this.props.clickedData.actionTaken,
                agreeFor: this.props.clickedData.agreeFor,
                agreeForDetail1: this.props.clickedData.agreeForDetail1,
                agreeForDetail2: this.props.clickedData.agreeForDetail2,
                agreeForDetail3: this.props.clickedData.agreeForDetail3,
                agreeForService: this.props.clickedData.agreeForService,
                clientComments: this.props.clickedData.clientComments,
                clientId: this.props.client.id,
                clientName: this.props.client.name,
                createdBy: this.props.clickedData.createdBy,
                domesticViolance: this.props.clickedData.domesticViolance,
                dropOff: this.props.clickedData.dropOff,
                inCharge: this.props.clickedData.inCharge,
                isAggression: this.props.clickedData.isAggression,
                isAmbulance: this.props.clickedData.isAmbulance,
                isArchived: this.props.clickedData.isArchived,
                isArrangements: this.props.clickedData.isArrangements,
                isBelonging: this.props.clickedData.isBelonging,
                isCheckout: this.props.clickedData.isCheckout,
                isLeaveDiversion: this.props.clickedData.isLeaveDiversion,
                isMedicalCondition: this.props.clickedData.isMedicalCondition,
                isOnMedication: this.props.clickedData.isOnMedication,
                isReferral: this.props.clickedData.isReferral,
                isSafePlace: this.props.clickedData.isSafePlace,
                isServerRefused: this.props.clickedData.isServerRefused,
                isViolence: this.props.clickedData.isViolence,
                leaveDiversionComment1: this.props.clickedData.leaveDiversionComment1,
                leaveDiversionComment2: this.props.clickedData.leaveDiversionComment2,
                medicalObservation: this.props.clickedData.medicalObservation,
                medicationDate: this.props.clickedData.medicationDate,
                medicationName: this.props.clickedData.medicationName,
                medicationTime: this.props.clickedData.medicationTime,
                safePlaceDetails: this.props.clickedData.safePlaceDetails,
                serverRefusedBy: this.props.clickedData.serverRefusedBy,
                thingsToConsider: this.props.clickedData.thingsToConsider,
                thingsToConsider2: this.props.clickedData.thingsToConsider2,
                thingsToConsider3: this.props.clickedData.thingsToConsider3,
                timeIn: new Date(this.props.clickedData.timeIn),
                violanceDetails: this.props.clickedData.violanceDetails,

            }))
            this.props.handleOnSaveCell(this.props.clickedData.clientEmail)
            // store.dispatch({
            //     type: CREATE_CLIENT_RES,
            //     payload: {}
            // })

            this.props.onClose()

        }
        if (JSON.stringify(prevProps.cellVisit) !== JSON.stringify(this.props.cellVisit)) {
            
            // let clientData = this.props.clients.find(elem => elem.email === this.state.value.value)
            this.props.handleOnSaveCell(this.props.clickedData.clientEmail)
            this.props.onClose()
        }
    }
    handleDropDown(param, name) {
        // let patrol = this.state.cellClickedData
        // if (value[0]) {
        //     patrol[name] = value[0].value
        // setSelectEmail(value)
        // setValue(param)
        this.setState({ value: param })
        //     this.setState({ cellClickedData: patrol })
        // }
        
    }
    handleOnSave() {
        let clientData = this.props.clients.find(elem => elem.email === this.state.value.value)
        store.dispatch(CreateCellVisitAction({
            actionTaken: this.props.clickedData.actionTaken,
            agreeFor: this.props.clickedData.agreeFor,
            agreeForDetail1: this.props.clickedData.agreeForDetail1,
            agreeForDetail2: this.props.clickedData.agreeForDetail2,
            agreeForDetail3: this.props.clickedData.agreeForDetail3,
            agreeForService: this.props.clickedData.agreeForService,
            clientComments: this.props.clickedData.clientComments,
            clientId: clientData.id,
            clientName: clientData.name,
            createdBy: this.props.clickedData.createdBy,
            domesticViolance: this.props.clickedData.domesticViolance,
            dropOff: this.props.clickedData.dropOff,
            inCharge: this.props.clickedData.inCharge,
            isAggression: this.props.clickedData.isAggression,
            isAmbulance: this.props.clickedData.isAmbulance,
            isArchived: this.props.clickedData.isArchived,
            isArrangements: this.props.clickedData.isArrangements,
            isBelonging: this.props.clickedData.isBelonging,
            isCheckout: this.props.clickedData.isCheckout,
            isLeaveDiversion: this.props.clickedData.isLeaveDiversion,
            isMedicalCondition: this.props.clickedData.isMedicalCondition,
            isOnMedication: this.props.clickedData.isOnMedication,
            isReferral: this.props.clickedData.isReferral,
            isSafePlace: this.props.clickedData.isSafePlace,
            isServerRefused: this.props.clickedData.isServerRefused,
            isViolence: this.props.clickedData.isViolence,
            leaveDiversionComment1: this.props.clickedData.leaveDiversionComment1,
            leaveDiversionComment2: this.props.clickedData.leaveDiversionComment2,
            medicalObservation: this.props.clickedData.medicalObservation,
            medicationDate: this.props.clickedData.medicationDate,
            medicationName: this.props.clickedData.medicationName,
            medicationTime: this.props.clickedData.medicationTime,
            safePlaceDetails: this.props.clickedData.safePlaceDetails,
            serverRefusedBy: this.props.clickedData.serverRefusedBy,
            thingsToConsider: this.props.clickedData.thingsToConsider,
            thingsToConsider2: this.props.clickedData.thingsToConsider2,
            thingsToConsider3: this.props.clickedData.thingsToConsider3,
            timeIn: new Date(this.props.clickedData.timeIn),
            violanceDetails: this.props.clickedData.violanceDetails,

        }))
        // this.props.handleOnSaveCell(clientData.email)
        // this.props.onClose()
    }
    handleOnCreate() {
        store.dispatch(CreateClientAction({
            firstName: this.props.clickedData.firstName,
            lastName: this.props.clickedData.lastName,
            alias1: '',
            alias2: '',
            alias3: '',
            dob: null,
            isDob: false,
            gender: 'Male',
            culturalIdentity: '',
            email: this.props.clickedData.clientEmail,
            address: '',
            isHomeless: false,
            isAddressUnkown: false,
            isBowmanJohnson: false,
            startDate: null,
            endDate: null
        }))
        // props.handleOnSave(props.clickedData.index, props.clickedData.email)
        // props.onClose()
    }

    render() {

        return (
            <div>
                <Modal size="lg" show={this.props.show} backdrop="static" keyboard={false} onHide={this.props.onClose} centered>
                    {/* <Modal.Header className="text-white" closeButton>
                <Modal.Title className="text-white ml-4">Confirm Cell Visit </Modal.Title>
            </Modal.Header> */}
                    <Modal.Body className="px-4 confirmation-modal">
                        <div>
                            <h4 className="confirmation-main__heading">Client Matched for this visit</h4>
                        </div>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <label className="confrimation-label">Client Email:</label>
                            </Col>
                            <Col>
                                <p className="confirmation-text">{this.props.clickedData.clientEmail || 'N/A'}</p>
                            </Col>
                            {/* <div >
                        <Button onClick={props.onClose} variant="link">Cancel</Button>
                        <Button variant="danger" onClick={() => onSave()} size="sm">Save</Button>
                    </div> */}
                        </Row>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <label className="confrimation-label"> First Name:</label>
                            </Col>
                            <Col>
                                <p className="confirmation-text"> {this.props.clickedData.firstName || 'N/A'}</p>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <label className="confrimation-label"> Last Name:</label>
                            </Col>
                            <Col>
                                <p className="confirmation-text"> {this.props.clickedData.lastName || 'N/A'}</p>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                            <Col>
                                <label className="confrimation-label"> Matched Email :</label>
                            </Col>
                            <Col>
                                <Select
                                    className="confirmation-select"
                                    placeholder="Select email"
                                    options={this.state.options}
                                    value={this.state.value}
                                    name="client"
                                    onChange={(e) => this.handleDropDown(e, 'location')}
                                />
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                            <Col className="text-left">
                                <Button className="main-btn match-btn" onClick={this.props.onClose} variant="link">Cancel</Button>
                            </Col>
                            <Col className="text-center">
                                <Button className="main-btn minimum-padding" disabled={!this.state.value} variant="danger" onClick={() => this.handleOnSave()} size="sm">Confirm</Button>
                                {/* {props.clickedData.firstName + props.clickedData.lastName || 'N/A'} */}
                            </Col>
                            <Col className="text-right">
                                <Button className="main-btn match-btn" disabled={this.state.value} variant="danger" onClick={() => this.handleOnCreate()} size="sm">Confirm and Create Client</Button>
                                {/* {props.clickedData.firstName + props.clickedData.lastName || 'N/A'} */}
                            </Col>
                        </Row>
                        {/* <p className="mb-3 ml-2 mr-2"> {props.params.body} {props.params.name}</p> */}
                        {/* <Form.Check
          type="checkbox"
          label="Don't show this message again"
          className=" ml-2 mr-2"
        /> */}
                        {/* <Table className="text-center">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Confirm Client</th>
                        </tr>
                    </thead>
                    <tbody> */}
                        {/* {this.props.patrols.map((patrol, index) => <tr style={{cursor: "pointer"}} onClick={async () => { store.dispatch({ type: COMMUNITY_PATROL_TAB, payload: patrol, IS_CLICKED_PATROL: true }); await store.dispatch(SearchClientsAction(`from=community&query=&id=${patrol.Client.id}`)) ;this.props.setKey('client')   }}> */}

                        {/* <tr style={{ cursor: "pointer" }} >
                            <td className={"bg-grey"}>{props.clickedData.clientEmail || 'N/A'}</td>
                            <td className={''}>{props.clickedData.firstName + props.clickedData.lastName || 'N/A'}</td>
                            <td className={"bg-grey"}>
                                <Select
                                    // className="selection-select arrow-down custom-width no-border"
                                    placeholder="Select Location"
                                    options={option}
                                    values={props.clients}
                                    name="client"
                                    onChange={(e) => handleDropDown(e, 'location')}
                                />
                            </td> */}
                        {/* <td className={"bg-grey"}>{patrol.phone || 'N/A'}</td> */}
                        {/* <td className={''}>{patrol.dob ? <Moment format={DATE_FORMAT}>{patrol.dob}</Moment> : 'N/A'}</td> */}
                        {/* <td className={"bg-grey"}><Moment format={DATE_FORMAT}>{patrol.timeIn}</Moment></td> */}
                        {/* <td className={''}><Moment format={TIME_FORMAT}>{patrol.timeIn}</Moment></td> */}
                        {/* <td className="bg-grey"></td> */}
                        {/* <td>
                                <img
                                    src={patrol.isCheckout ? require("../../assets/images/orange-checkbox.svg") : require("../../assets/images/grey-checkbox.svg")}
                                    alt="logo"
                                />
                            </td> */}
                        {/* </tr>

                    </tbody>
                </Table> */}
                        {/* <div >
                    <Button onClick={props.onClose} variant="link">Cancel</Button>
                    <Button variant="danger" onClick={() => onSave()} size="sm">Save</Button>
                </div> */}

                    </Modal.Body>
                    {/* <Modal.Footer className="d-flex align-items-center">
                
            </Modal.Footer> */}
                </Modal>
            </div>
        )
    }
}

// export default ConfirmationModal;
const stateMap = (state) => {
    return {
        // patrols: state.communityPatrol.communityPatrols,
        // searchResult: state.communityPatrol.activePatrolClients
        created: state.client.created,
        client: state.client.client,
        cellVisit: state.cellVisit.cellVisit,
    };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(ConfirmationModal);

