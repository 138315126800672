import React from 'react';
import validator from 'validator';
import moment from "moment"


export const required = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return <span className="error text-danger p-1">This field is required.</span>
    }
};
export const nonRequired = (value) => {

   return <span>value</span>
};
export const numbers = (value) => {
    var reg = new RegExp(/^\d+$/);
    if (value.toString().trim().length > 1 && !reg.test(value)) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return <span className="error text-danger p-1">Only positive numbers are allowed</span>
    }
};

export const onlyFuture = (value) => {
    if (!moment(value).isAfter())
        return <span className="error text-danger p-1">Date can not be set to past.</span>
};
export const onlyPast = (value) => {
    if (value && !moment(value).isBefore())
        return <span className="error text-danger p-1">Date can not be set to future.</span>
};
export const gt0 = (value) => {
    if (value.toString().trim().length > 1 && !parseInt(value) > 0)
        return <span className="error text-danger p-1">Number should be greater then '0'.</span>
};

export const max300 = (value) => {
    
    if (!value && parseInt(value) > 300)
        return <span className="error text-danger p-1">Max limit is 300</span>
};
export const max100 = (value) => {
    
    if (!value && parseInt(value) > 100)
        return <span className="error text-danger p-1">Max limit is 100</span>
};
export const max200 = (value) => {
    // 
    if (!value && parseInt(value) > 200)
        return <span className="error text-danger p-1">Max limit is 200</span>
};
export const max15 = (value) => {
    
    if (!value && parseInt(value) > 15)
        return <span className="error text-danger p-1">Max limit is 300</span>
};
export const email = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (!validator.isEmail(value)) {
        return <span className="error text-danger p-1">`{value}` is not a valid email.</span>
    }
};

export const max20 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length > 20) {
        return <span className="error text-danger p-1">Field is too long.</span>
    }
};

export const max2 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length > 2) {
        return <span className="error text-danger p-1">Field is too long.</span>
    }
};

export const min3 = (value) => {
    if (value.toString().trim().length > 1 && value.toString().trim().length < 3) {
        return <span className="error text-danger p-1">Field is too short.</span>
    }
};

export const min1 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length < 1) {
        return <span className="error text-danger p-1">Name is too short.</span>
    }
};

export const min2 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length < 2) {
        return <span className="error text-danger p-1">Field is too short.</span>
    }
};

export const min8 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length < 8) {
        return <span className="error text-danger p-1">Password should contain minmum 8 character.</span>
    }
};
export const max2000 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length > 2000) {
        return <span className="error text-danger p-1">Max charactors limit is 500.</span>
    }
};
export const max50 = (value) => {
    if (!value)
        return <span className="error text-danger p-1">This field is required.</span>
    if (value.toString().trim().length > 50) {
        return <span className="error text-danger p-1">Max charactors limit is 50.</span>
    }
};

export const password = (value, props, components) => {
    // /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/ //Password must include (one uppercase, one lowercase, one special character, one number and should have 10 characters)
    if (!components['password'][0].value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error text-danger p-1">Password must include (one uppercase, one lowercase, one number and minimum 8 characters)</span>
    }
    if (components['confirmPassword'][0].value.length > 1 && components['password'][0].value !== components['confirmPassword'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        return <span className="error text-danger p-1">Password not matched.</span>
    }
};