import { LOGIN_RES, USER_RES, REGISTER_RES, LOGIN_MESSAGE, REGISTER_FAIL, RESET_PASSWORD_RES, BUSINESS_CARD, IS_LOGIN, RESET_USER, IS_VERIFY, CONFIRM_SIGNUP_RES, ALLOW_TO_VERIFY, CREATE_USER_RES, LIST_USERS, USER_UPDATED, ARCHIVE_USER_RES, USER_ARCHIVED, TOTAL_USERS, TOTAL_SEARCH_USERS, RESTORE_USER_RES } from "../helpers/constants";

export default function user(state = {
    user: {},
    publicUser: {},
    loginMessage: '',
    resetPassword: {},
    confirmSignUp: '',
    isLoggedIn: false,
    allowToVerify: false,
    registerMessage: '',
    isRegistered: '',
    cards: [],
    userSearch: [],
    users: [],
    admin: {},
    notConfirm: true,
    updated: true,
    totalUsers: 0,
    totalSearchUsers: 0,
    archived: true
}, action) {
    switch (action.type) {
        case LOGIN_RES:
            {
                return { ...state, user: action.payload };
            }
        case TOTAL_USERS:
            {
                return { ...state, totalUsers: action.payload };
            }
        case TOTAL_SEARCH_USERS:
            {
                return { ...state, totalSearchUsers: action.payload };
            }
        case IS_VERIFY:
            {
                return { ...state, user: { ...state.user, ...action.payload } };
            }
        case USER_RES:
            {

                return { ...state, publicUser: action.payload };
            }
        case ARCHIVE_USER_RES:
            {

                return { ...state, admin: action.payload };
            }
        case RESTORE_USER_RES:
            {

                return { ...state, admin: action.payload };
            }
        case USER_UPDATED:
            {

                return { ...state, updated: action.payload };
            }
        case USER_ARCHIVED:
            {

                return { ...state, archived: action.payload };
            }
        case CREATE_USER_RES:
            {

                return { ...state, admin: action.payload };
            }
        case 'SEARCHED_LIST_USERS':
            {

                return { ...state, userSearch: action.payload };
            }
        case REGISTER_RES:
            {
                return { ...state, registerMessage: action.payload, isRegistered: true };
            }
        case IS_LOGIN:
            {
                if (action.payload) {
                    return { ...state, isLoggedIn: action.payload };
                } else {
                    return { state: {}, isLoggedIn: action.payload };

                }
            }
        case REGISTER_FAIL:
            {
                return { ...state, registerMessage: action.payload, isRegistered: false };
            }
        case ALLOW_TO_VERIFY:
            {
                return { ...state, allowToVerify: action.payload };
            }
        case LIST_USERS: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, users: action.payload }
                }
                return { ...state, users: [...state.users, action.payload] };
            }
        case LOGIN_MESSAGE:
            {
                return { ...state, loginMessage: action.payload };
            }
        case RESET_PASSWORD_RES:
            {
                return { ...state, resetPassword: action.payload };
            }
        case RESET_USER:
            {
                return {
                    user: {},
                    loginMessage: '',
                    isLoggedIn: '',
                    registerMessage: '',
                    isRegistered: '',
                };
            }
        case CONFIRM_SIGNUP_RES:
            {

                return { ...state, confirmSignUp: action.payload, notConfirm: action.notConfirm };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}