import React from "react";
import Header from "../../components/shared/header/header";
import Sidebar from "../../components/shared/sidebar/sidebar";
import CellVisitForm from "../../components/cell-visits/cell-visits";
import { DEFAULT_SIDEBAR_OPEN } from "../../helpers/constants";
import ConfirmCellVisitTable from "./confirm-cell-visit-table"
import { getClientListAction } from "../../actions/client-action";
import store from "../../store";
import { connect } from "react-redux";

class MainConfirmCellVisitor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSidebarOpen: DEFAULT_SIDEBAR_OPEN,
            clientToConfirm: [],
            clientForm: []
        }
    }
    componentDidMount() {
        // 
        store.dispatch(getClientListAction())
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.clients) !== JSON.stringify(this.props.clients)) {
            // this.setState({ key: 'actives' })
            
            if (localStorage.getItem('cellVisits')) {
                let forms = JSON.parse(localStorage.getItem('cellVisits'))
                this.setState({ clientForm: forms })
                this.setState({ clientToConfirm: this.props.clients.filter(elem => forms.some(form => elem.email === form.clientEmail)) })
            }
        }

    }
    render() {
        return (
            <div className="admin-content" style={{ display: 'flex' }}>
                <Header handleSidebar={() => this.setState({ isSidebarOpen: !this.state.isSidebarOpen })} {...this.props} />
                <Sidebar isSidebarOpen={this.state.isSidebarOpen} {...this.props}>
                    {this.state.clientToConfirm.length > 0 &&
                        <ConfirmCellVisitTable clientToConfirm={this.state.clientToConfirm} parentRef={this} clientForm={this.state.clientForm} {...this.props} />
                    }
                </Sidebar>
            </div>
        )
    }
}
const stateMap = (state) => {
    return {
        // patrols: state.communityPatrol.communityPatrols,
        clients: state.client.clientList
        // searchResult: state.communityPatrol.activePatrolClients
    };
};
// export default connect(stateMap)(MainClientsContainer);
export default connect(stateMap)(MainConfirmCellVisitor);
