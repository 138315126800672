import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "./utility";

export default ({ component: C, appProps, ...rest }) =>{
    useEffect(() => {
        if(appProps.isAuthenticated)
        toast("You are already Authenticated");
    });
    return(
    <Route
        {...rest}
        exact
        render={props =>
            !appProps.isAuthenticated
                ? <C {...props} {...appProps} />
                : <Redirect to="/" />}
    />)
}