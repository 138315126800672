
import { http } from "./http";
import { FETCH_REPORT, FETCH_REPORT_CELL, FETCH_REPORT_COMMUNITY, FETCH_REPORT_DIVERSION, DOWNLOAD_REPORT_CELL, DOWNLOAD_REPORT_COMMUNITY, DOWNLOAD_REPORT_DIVERSION } from "./constants";

export const getSystemReport = (data) => {
    return data.location ?
        http.get(FETCH_REPORT + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(FETCH_REPORT + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemCellReport = (data) => {
    return data.location ?
        http.get(FETCH_REPORT_CELL + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(FETCH_REPORT_CELL + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemCellReportDownload = (data) => {
    return data.location ?
        http.get(DOWNLOAD_REPORT_CELL + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(DOWNLOAD_REPORT_CELL + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemCommunityReport = (data) => {
    return data.location ?
        http.get(FETCH_REPORT_COMMUNITY + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(FETCH_REPORT_COMMUNITY + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemCommunityReportDownload = (data) => {
    return data.location ?
        http.get(DOWNLOAD_REPORT_COMMUNITY + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(DOWNLOAD_REPORT_COMMUNITY + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemDiversionReport = (data) => {
    return data.location ?
        http.get(FETCH_REPORT_DIVERSION + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(FETCH_REPORT_DIVERSION + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}

export const getSystemDiversionReportDownload = (data) => {
    return data.location ?
        http.get(DOWNLOAD_REPORT_DIVERSION + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge + '/' + data.location) :
        http.get(DOWNLOAD_REPORT_DIVERSION + '/' + data.startDate + '/' + data.endDate + '/' + data.minAge + '/' + data.maxAge);
}