import { createClientPatrol, getClientPatrol, updateClientPatrol, getPatrol } from '../api/community-patrol-api';
import { COMMUNITY_PATROLS, COMMUNITY_PATROL_SAVE } from '../helpers/constants';
require('es6-promise').polyfill();

export function CreateClientPatrolAction(data) {
    return async function (dispatch) {
        const res = await createClientPatrol(data)
        dispatch({
            type: COMMUNITY_PATROL_SAVE,
            payload: res.data.data.communityPatrol
        });
    };
}

export function updateClientPatrolAction(data) {
    return async function (dispatch) {
       
        const res = await updateClientPatrol(data)
        dispatch({
            type: COMMUNITY_PATROL_SAVE,
            payload: res.data.data.communityPatrol
        });
    };
}
export function getCommunityPatrolsAction(data) {
    return async function (dispatch) {
        const res = await getClientPatrol(data)
        dispatch({
            type: COMMUNITY_PATROLS,
            payload: res.data.data.communityPatrols
        });
    };
}
export function getSingleCommunityPatrols(id) {
    return async function (dispatch) {
        const res = await getPatrol(id)
        dispatch({
            type: COMMUNITY_PATROL_SAVE,
            payload: res.data.data.communityPatrol[0]
        });
    };
}
