import { CLIENT_OBSERVATION, IS_CLIENT_OBSERVATION } from "../helpers/constants";

export default function client(state = {
    isClientObservation: false,
    clientObservations: []
}, action) {
    switch (action.type) {
        case CLIENT_OBSERVATION:
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, clientObservations: action.payload }
                }
                return { ...state, clientObservations: [...state.clientObservations, action.payload] };
            }
        case IS_CLIENT_OBSERVATION:
            {
                return { ...state, isClientObservation: action.payload };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}