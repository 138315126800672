import { LOGIN_RES, USER_RES, RESET_PASSWORD_REQUEST, USER_UPDATED, RESET_PASSWORD_RES, LIST_USERS, CREATE_USER_RES, ARCHIVE_USER_RES, USER_ARCHIVED, TOTAL_USERS, TOTAL_SEARCH_USERS, INVITE_USER, RESTORE_USER_RES } from '../../helpers/constants';
import { profile, getProfileByEmail, profileUpdate, resetPasswordRequest, verifyPasswordToken, resetPassword, listUsers, getUser, userUpdate, userArchive, listSearchedUsers, inviteAgain, userRestore } from '../../api/user/user-api';
import { setUser, toast } from '../../helpers/utility';
require('es6-promise').polyfill();

export function GetProfileAction() {
    return async function (dispatch) {
        const res = await profile()
        if (!res.data.data.error) {
            dispatch({
                type: LOGIN_RES,
                payload: res.data.data.user
            });
            setUser(res.data.data.user)
        } else {
            console.log(res);
        }
    };
}
export function GetUserByEmailAction(data) {
    return async function (dispatch) {
        const res = await getProfileByEmail(data)
        if (!res.data.data.error) {
            if (!res.data.data.user) {
                dispatch({
                    type: USER_RES,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: USER_RES,
                    payload: res.data.data.user
                });
            }
        }
        else {
            console.log(res);
        }
    };
}
export function GetUsersAction(data) {
    return async function (dispatch) {
        const res = await listUsers(data)
        if (!res.data.data.error) {
            dispatch({
                type: LIST_USERS,
                payload: res.data.data.user
            });
            if (res.data.data.total >= 0) {
                dispatch({
                    type: TOTAL_USERS,
                    payload: res.data.data.total
                });
            }
        }
        else {
            console.log(res);
        }
    };
}
export function GetSearchedUsersAction(key, page) {
    return async function (dispatch) {
        const res = await listSearchedUsers(key, page)
        if (!res.data.data.error) {
            dispatch({
                type: 'SEARCHED_LIST_USERS',
                payload: res.data.data.users
            });
            if (res.data.data.total) {
                dispatch({
                    type: TOTAL_SEARCH_USERS,
                    payload: res.data.data.total
                });
            }
        }
        else {
            console.log(res);
        }
    };
}
export function GetUserAction(id) {
    return async function (dispatch) {
        const res = await getUser(id)
        if (!res.data.data.error) {
            dispatch({
                type: CREATE_USER_RES,
                payload: res.data.data.user
            });
        }
        else {
            console.log(res);
        }
    };
}
export function UpdateProfileAction(data) {
    return async function (dispatch) {
        const res = await profileUpdate(data)
        if (!res.data.data.error) {
            setUser(res.data.data.user)
            toast('Profile Updated')
            dispatch({
                type: LOGIN_RES,
                payload: res.data.data.user
            });
        } else {
            console.log(res);
        }
    };
}
export function UpdateUserAction(data) {
    return async function (dispatch) {
        const res = await userUpdate(data)
        if (!res.data.data.error) {
            dispatch({
                type: CREATE_USER_RES,
                payload: res.data.data.user
            });
            dispatch({
                type: USER_UPDATED,
                payload: new Date().getTime()
            });
        } else {
            console.log(res);
        }
    };
}
export function archiveUserAction(id) {
    return async function (dispatch) {
        const res = await userArchive(id)
        if (!res.data.data.error) {
            dispatch({
                type: ARCHIVE_USER_RES,
                payload: res.data.data.user
            });
            dispatch({
                type: USER_ARCHIVED,
                payload: new Date().getTime()
            });
        } else {
            console.log(res);
        }
    }
}
export function restoreUserAction(id) {
    return async function (dispatch) {
        const res = await userRestore(id)
        if (!res.data.data.error) {
            dispatch({
                type: RESTORE_USER_RES,
                payload: res.data.data.user
            });
        } else {
            console.log(res);
        }
    }
}
export function InviteAgainAction(data) {
    return async function (dispatch) {
        const res = await inviteAgain(data)
        // console.log(res)
        if (!res.data.data.error) {
            toast('Invite sent again sucessfuly')
            dispatch({
                type: INVITE_USER,
                payload: res.data.data.user
            });
        } else {
            console.log(res);
        }
    }
}
export function ResetPasswordRequest(data) {
    return async function (dispatch) {
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: {}
        });
        const res = await resetPasswordRequest(data)
        console.log(res);
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: res.data.data
        });
    };
}
export function VeirfyTokenAction(token) {
    return async function (dispatch) {
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: {}
        });
        const res = await verifyPasswordToken(token)
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: res.data.data
        });
    };
}
export function ResetPasswordAction(token) {
    return async function (dispatch) {
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: {}
        });
        const res = await resetPassword(token)
        dispatch({
            type: RESET_PASSWORD_RES,
            payload: res.data.data
        });
    };
}