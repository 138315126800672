
import { http } from "./http";
import { CREATE_CELL_VISIT } from "./constants";

export const createCellVisit = async (data) => {
    // 
    return http.post(CREATE_CELL_VISIT, data)
}
export const getCellVisit = async (data) => {
    return http.get(CREATE_CELL_VISIT, data)
}

export const getCell = async (id) => {
    return http.get(CREATE_CELL_VISIT + '/' + id)
}
export const updateCellVisit = async (data) => {
    return http.put(CREATE_CELL_VISIT + '/' + data.id, data)
}

