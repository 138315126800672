import { LOCATION_RES ,CREATE_LOCATION_RES} from "../helpers/constants";

export default function location(state = {
    location: {},
    locations: [],
    // totalContacts: 0,
    // searchResult: false
}, action) {
    switch (action.type) {
        case CREATE_LOCATION_RES: //on load api call response will store here
            {
                return { ...state, location: action.payload };
            }
        case LOCATION_RES: //on load api call response will store here
            {
                if (action.payload.constructor.name === "Array") {
                    return { ...state, locations: action.payload }
                }
                return { ...state, locations: [...state.locations, action.payload] };
            }
        default:
            {
                if (action.type.indexOf('@@') === -1) {
                    // 
                }
            }
    }
    return state;
}