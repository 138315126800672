import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import Search from "../../components/search/search";
import Select from 'react-dropdown-select';

import store from '../../store';
import { connect } from "react-redux";
import CellVisitDashboard from "../../components/dashboard/cell-visit-dashboad";
import TableCellVisit from "../../components/cell-visits/table-cell-visit";
import UserDetailCellVisit from "../../components/cell-visits/user-detail-cell-visit";
import "./cell-visits.scss";
import { internetConnected, toast, getUser } from '../../helpers/utility';
// import ConfirmCellVisitTable from "./confirm-cell-visit-table"
import ConfirmCellVisitTable from "../confirm-cell-visit/confirm-cell-visit-table"

import { CreateCellVisitAction, updateCellVisitAction } from '../../actions/cell-visit';
import { CELL_VISIT_TAB, IS_CLICKED_CELL, SEARCH_CLIENT_RES, IS_MEDICAL_OBSERVATION, MEDICAL_OBSERVATION } from '../../helpers/constants';
import { getClientListAction, SearchClientsAction } from '../../actions/client-action';
import { GetContactsAction } from '../../actions/contact-action';
import { GetLocationAction } from '../../actions/location-action';

class CellVisitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'actives',
            isSaveButtons: false,
            selectedClientId: '',
            isMedicalCheck: false,
            clientToConfirm: [],
            locations: [],
            clientForm: [],
            search: '',
            cellClickedData: {},
            concentClickedData: {
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
            },
            cell: {
                dropOff: '',
                timeIn: '',
                safePlaceDetails: '',
                violanceDetails: '',
                clientComments: '',
                actionTaken: '',
                isCellvisit: false,
                inCharge: '',

                isViolence: false,
                isMedicalCondition: false,
                isServerRefused: false,
                isCheckout: false,
                isBelonging: false,
                isSafePlace: false,
                isReferral: false,
                isLeaveDiversion: 1,
                leaveDiversionComment1: "",
                leaveDiversionComment2: "",
                isArchived: false,
                clientId: 0,
                createdBy: 0,
                serverRefusedBy: 0,
                isAmbulance: false,
                isArrangements: 0,
                isAggression: false,
                isOnMedication: false,
                thingsToConsider: false,
                thingsToConsider2: false,
                thingsToConsider3: false,
                estimatedTime: 0,
                medicationName: '',
                medicationDate: '',
                medicationTime: '',
            }
        };
        this.searchChanged = this.searchChanged.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeConcent = this.onChangeConcent.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.handleDropDown = this.handleDropDown.bind(this);
        this.tabChangeHandler = this.tabChangeHandler.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
    }

    onChange(e, loc, id) {
        let cellClickedData = this.state.cellClickedData
        if (loc === 'check') {
            cellClickedData[e.target.name] = eval(e.target.value)
            if (e.target.name == 'isMedicalCondition') {
                this.setState({ isMedicalCheck: eval(e.target.value) })
                store.dispatch({
                    type: IS_MEDICAL_OBSERVATION,
                    payload: eval(e.target.value)
                });
            }
            if (e.target.name == 'isServerRefused' && eval(e.target.value)) {
                cellClickedData['isLeaveDiversion'] = 4
                cellClickedData['leaveDiversionComment2'] = "Service Refused"
            }
        } else {
            cellClickedData[e.target.name] = e.target.value
        }
        this.setState({ cellClickedData })
    }
    onChangeConcent(e, loc, id) {
        let concentClickedData = this.state.concentClickedData
        concentClickedData[e.target.name] = eval(e.target.value)
        this.setState({ concentClickedData })
    }

    componentDidMount() {
        this.setState({ cellClickedData: this.state.cell });
        if (internetConnected()) {
            store.dispatch(getClientListAction())
            store.dispatch(GetLocationAction());
        }
        if (this.props.match.params.key) {
            this.tabChangeHandler(this.props.match.params.key);
        }
        if (this.props.match.params.id && this.props.searchClients.length > 0) {
            this.setState({ selectedClientId: this.props.match.params.id })
            setTimeout(() => {
                this.onRowSelect(false, this.props.match.params.id)
            }, 1000)
        } else {
            store.dispatch(SearchClientsAction(`from=cell&query=&page=1`))
        }
        if (localStorage.getItem('cellVisits')) {
            let forms = JSON.parse(localStorage.getItem('cellVisits'))
            this.setState({ clientForm: forms })
            this.setState({ clientToConfirm: forms })
        }
        if (this.props.locations && this.props.locations.length > 0) {
            // this.setState({ key: 'actives' })
            let locations = this.props.locations.filter(elem => elem.cellVisit)
            console.log(locations)
            this.setState({ locations: locations.map(elem => elem = { label: elem.name, value: elem.id, name: elem.name }) })
        }
        store.dispatch(GetContactsAction())
    }
    onClose() {
        let data = this.state.cellClickedData
        data.isReferral = false
        this.setState({ cellClickedData: data })
    }
    onSave() {
        this.setState({ cellClickedData: { ...this.state.cellClickedData, ...this.state.concentClickedData } })
    }

    onRowSelect(e, id) {
        this.setState({ selectedClientId: id });
        let selectedClient = this.props.searchClients.filter(elem => elem.id === id);

        if (selectedClient.length > 0 && selectedClient[0].CellVisits && selectedClient[0].CellVisits.length > 0) {
            this.tabChangeHandler('client');
            this.setState({
                cellClickedData: {
                    ...selectedClient[0].CellVisits[0],
                    Client: {
                        id: selectedClient[0].id, name: selectedClient[0].name, firstName: selectedClient[0].firstName,
                        lastName: selectedClient[0].lastName, dob: selectedClient[0].dob, phone: selectedClient[0].phone
                    }
                }, isSaveButtons: false
            }, () => {
                store.dispatch({ type: CELL_VISIT_TAB, payload: { ...this.state.cellClickedData } })
                store.dispatch({ type: IS_CLICKED_CELL, payload: true });
            })
        } else {
            let cellClickedData = this.state.cell;
            cellClickedData['clientId'] = id;
            cellClickedData['timeIn'] = new Date();
            this.setState({ cellClickedData, isSaveButtons: true }, () => {
                store.dispatch({ type: IS_CLICKED_CELL, payload: true });
            });
        }
    }

    handleOnSubmit(e, flag) {
        e.preventDefault();

        let cellClickedData = this.state.cellClickedData
        cellClickedData["serviceLocation"] = getUser().location
        if (this.props.isMedicalObservation) {
            cellClickedData['medicalObservation'] = this.props.medicalObservations
        } else {
            cellClickedData['medicalObservation'] = []
        }
        if (this.props.domesticViolance) {
            cellClickedData['domesticViolance'] = this.props.domesticViolance
        } else {
            cellClickedData['domesticViolance'] = []
        }
        if (!cellClickedData.isReferral) {
            cellClickedData['agreeFor'] = false
            cellClickedData['isReferralSupport'] = false
            cellClickedData['agreeForDetail1'] = ''
            cellClickedData['agreeForDetail2'] = ''
            cellClickedData['agreeForDetail3'] = ''
            cellClickedData['agreeForService'] = 0
            cellClickedData['clientName'] = ''
            cellClickedData['thingsToConsider'] = false
            cellClickedData['thingsToConsider2'] = false
            cellClickedData['thingsToConsider3'] = false
        }
        if (cellClickedData.isSafePlace &&
            (!cellClickedData.safePlaceDetails || cellClickedData.safePlaceDetails == '')) {
            toast('Please provide SAFE PLACE contact person details', 'error')
            return false;
        } else {
            if (!cellClickedData.isSafePlace) {
                cellClickedData["safePlaceDetails"] = ""
                this.setState({ cellClickedData })
            }
        }
        if (cellClickedData.isServerRefused &&
            (cellClickedData.serverRefusedBy == 0)) {
            toast('Please provide Service Refused By', 'error')
            return false;
        } else {
            if (!cellClickedData.isServerRefused) {
                cellClickedData["serverRefusedBy"] = 0
                this.setState({ cellClickedData })
            }
        }
        if (!cellClickedData.locationId || cellClickedData.locationId === null) {
            toast('Please provide Location', 'error')
            return false;
        }
        if (!cellClickedData.inCharge || cellClickedData.inCharge === null) {
            toast('Please provide officer In charge details', 'error')
            return false;
        }
        if (!cellClickedData.accessComment) {
            toast('Please indicate how the client accessed the service', 'error')
            return false;
        }
        if (cellClickedData.isOnMedication &&
            (!cellClickedData.medicationName || cellClickedData.medicationName == '')) {
            toast('Please provide Medication name', 'error')
            return false;
        } else {
            if (!cellClickedData.isOnMedication) {
                cellClickedData["medicationName"] = ""
                this.setState({ cellClickedData })
            }
        }
        if (flag === 'checkOut') {
            if (cellClickedData.isLeaveDiversion === 1 &&
                (!cellClickedData.leaveDiversionComment1 || cellClickedData.leaveDiversionComment1 === '')) {
                toast('Please indicate how the client left the service', 'error')
                return false;
            } else {
                if (cellClickedData.isLeaveDiversion === 4 || cellClickedData.isLeaveDiversion === 3 || cellClickedData.isLeaveDiversion === 2) {
                    cellClickedData["leaveDiversionComment1"] = ""
                    this.setState({ cellClickedData })
                }
            }
            if (cellClickedData.isLeaveDiversion === 4 &&
                (!cellClickedData.leaveDiversionComment2 || cellClickedData.leaveDiversionComment2 === '')) {
                toast('Please provide the details', 'error')
                return false;
            } else {
                if (cellClickedData.isLeaveDiversion === 1 || cellClickedData.isLeaveDiversion === 3 || cellClickedData.isLeaveDiversion === 2) {
                    cellClickedData["leaveDiversionComment2"] = ""
                    this.setState({ cellClickedData })
                }
            }
        }
        if (flag === 'checkOut' && !cellClickedData.isLeaveDiversion) {
            toast('Please provide client leave service', 'error')
            return false;
        }
        if (flag === 'checkOut' && (!cellClickedData.estimatedTime || cellClickedData.estimatedTime < 1)) {
            toast('Please provide client Estimated Time', 'error')
            return false;
        }
        if (!this.state.isSaveButtons) {
            if (flag == 'checkOut') {
                cellClickedData['isCheckout'] = true
                cellClickedData['checkoutAt'] = new Date()
                store.dispatch(updateCellVisitAction(cellClickedData))
            } else {
                store.dispatch(updateCellVisitAction(cellClickedData))
            }
        } else {
            store.dispatch(CreateCellVisitAction(cellClickedData))
        }
        this.setState({ cellClickedData: this.state.cell })
        store.dispatch({ type: MEDICAL_OBSERVATION, payload: [] });
        store.dispatch({ type: IS_MEDICAL_OBSERVATION, payload: false });
        store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        this.setState({ isSaveButtons: false })
    }

    componentDidUpdate(prevProp) {
        if (this.props.newSearch !== prevProp.newSearch) {
            this.setState({ selectedClientId: '' })
        }
        if (this.props.cellVisitTab && this.props.cellVisitTab !== prevProp.cellVisitTab) {
            this.setState({ cellClickedData: this.props.cellVisitTab })
        }
        if (JSON.stringify(prevProp.cellVisit) !== JSON.stringify(this.props.cellVisit)) {
            this.setState({ key: 'actives' })
        }
        if (JSON.stringify(prevProp.locations) !== JSON.stringify(this.props.locations)) {
            // this.setState({ key: 'actives' })
            let locations = this.props.locations.filter(elem => elem.cellVisit)
            console.log(locations)
            this.setState({ locations: locations.map(elem => elem = { label: elem.name, value: elem.id, name: elem.name }) })
        }
        if (JSON.stringify(prevProp.clients) !== JSON.stringify(this.props.clients)) {
            if (localStorage.getItem('cellVisits')) {
                let forms = JSON.parse(localStorage.getItem('cellVisits'))
                this.setState({ clientForm: forms })
                this.setState({ clientToConfirm: forms })
            }
        }
    }
    searchChanged(search) {
        this.setState({ search })
    }
    handleDropDown(value, name) {
        console.log(value)
        let patrol = this.state.cellClickedData
        if (value[0]) {
            patrol[name] = value[0].value
            this.setState({ cellClickedData: patrol })
        }
    }
    tabChangeHandler(key) {

        switch (key) {
            case 'actives':
                break;
            case 'new':
                if (!this.props.match.params.id && this.props.searchClients.length > 1) {
                    // store.dispatch({ type: DIVERSION_TAB, payload: {} })
                    store.dispatch({ type: IS_CLICKED_CELL, payload: false });
                }
                break;
            case 'client':
                break;
            default:
                break;
        }
        this.setState({ key })
    }
    componentWillUnmount() {

        store.dispatch({ type: IS_CLICKED_CELL, payload: false });
        store.dispatch({ type: CELL_VISIT_TAB, payload: {} })
    }

    render() {

        const options = [
            { label: "Brisbane", value: 1 },
            { label: "Bowman Johnson", value: 2 },
            { label: "Mackay", value: 3 },
            { label: "Townsville", value: 4 },
            { label: "Palm Island", value: 5 },
        ];
        return (
            <div className="cell-visits-section">
                <div className="tabs-section full-width mt-4">
                    <Tabs defaultActiveKey="actives" activeKey={this.state.key} onSelect={(key) => this.tabChangeHandler(key)} id="uncontrolled-tab-example">
                        <Tab eventKey="actives" title={<span>Active </span>} className="tab-table">
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'actives' && <CellVisitDashboard setKey={(key) => this.setState({ key })} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="new" title={<span>Add New</span>} className="tab-table comunity-user">
                            {this.state.key === 'new' && <Search from={'cell'} searchChanged={this.searchChanged} {...this.props} />}
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'new' && <TableCellVisit search={this.state.search} handleDropDown={this.handleDropDown} cellVisitLocations={this.state.locations} isOnlyHead={this.props.isClickedCellClients} patrol={this.state.cellClickedData} clientId={this.state.selectedClientId} onChange={this.onChange} onRowSelect={this.onRowSelect} {...this.props} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'new' && this.props.isClickedCellClients && <UserDetailCellVisit isMedicalCheck={this.state.isMedicalCheck} contacts={this.props.contacts} handleDropDown={this.handleDropDown} concentClickedData={this.state.concentClickedData} onSave={this.onSave} changeState={this.onClose} isSaveButtons={this.state.isSaveButtons} patrol={this.state.cellClickedData} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>
                        {this.props.cellVisitTab.id && <Tab eventKey="client" title={<span>{this.props.cellVisitTab.Client.name}</span>} className="tab-table comunity-user">
                            {/* <Search {...this.props} /> */}
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' && <TableCellVisit isOnlyHead={this.props.isClickedCellClients} clientTab={true} handleDropDown={this.handleDropDown} cellVisitLocations={this.state.locations} onChange={this.onChange} patrol={this.props.cellVisitTab} onRowSelect={this.onRowSelect} clientId={this.props.cellVisitTab.Client.id} {...this.props} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'client' && <UserDetailCellVisit isMedicalCheck={this.state.isMedicalCheck} contacts={this.props.contacts} clientTab={true} concentClickedData={this.state.concentClickedData} handleDropDown={this.handleDropDown} changeState={this.onClose} onSave={this.onSave} isSaveButtons={false} patrol={this.props.cellVisitTab} onhandleOnSubmit={this.handleOnSubmit} onChange={this.onChange} onChangeConcent={this.onChangeConcent} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>}
                        <Tab eventKey="sync" title={<span>Sync </span>} className="tab-table">
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'sync' && <ConfirmCellVisitTable synched={false} isActive={false} clientToConfirm={this.state.clientToConfirm} parentRef={this} clientForm={this.state.clientForm} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="synced" title={<span>Synced </span>} className="tab-table">
                            <Row>
                                <Col md={12}>
                                    {this.state.key === 'synced' && <ConfirmCellVisitTable synched={true} isActive={false} clientToConfirm={this.state.clientToConfirm} parentRef={this} clientForm={this.state.clientForm} {...this.props} />}
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
            </div>

        )
    }
}



const stateMap = (state) => {
    return {
        searchClients: state.client.searchClients,
        searchResult: state.client.searchResult,
        totalClients: state.client.totalClients,
        clickedCellClients: state.cellVisit.clickedCellClients,
        isClickedCellClients: state.cellVisit.isClickedCellClients,
        cellVisit: state.cellVisit.cellVisit,
        cellVisitTab: state.cellVisit.cellVisitTab,
        contacts: state.contact.contacts,
        newSearch: state.client.newSearch,
        isMedicalObservation: state.medicalObservation.isMedicalObservation,
        medicalObservations: state.medicalObservation.medicalObservations,
        isDomesticViolance: state.domesticViolance.isDomesticViolance,
        domesticViolance: state.domesticViolance.domesticViolance,
        clients: state.client.clientList,
        locations: state.location.locations,
    };
};
export default connect(stateMap)(CellVisitForm);